import { Box } from "@mui/material";
import ShipmentForm from "../components/ShipmentForm";
import { useState, useEffect } from "react";
import { currencies, Ekspedisi } from "src/utils/paramData";
import PageTitle from "src/components/main/CustomText/PageTitle";
import { getPurchaseShipmentDetail } from "src/api/purchase";
import { useLocation, useNavigate } from "react-router-dom";

export default function ShipmentDetail() {
  const [data, setData] = useState<PurchaseShipment>({
    type: "PurchaseShipment",
    currency: currencies.length > 0 ? currencies[0].value : "",
    expedition: Ekspedisi.length > 0 ? Ekspedisi[0].value : "",
  } as PurchaseShipment);

  const id = useLocation().pathname.split("/").pop();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevData: PurchaseShipment) => ({ ...prevData, [name]: value }));
  };

  const fetchData = async () => {
    const response = await getPurchaseShipmentDetail(id!);

    if (response?.status !== 200) {
      navigate("/purchase/shipment");
    }

    setData(response?.data?.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const req = {
      ...data,
    };

  };
  return (
    <Box>
      <PageTitle>Shipment Detail</PageTitle>
      <ShipmentForm
        disabled={true}
        data={data}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onUpload={() => {}}
        onDelete={() => {}}
      />
    </Box>
  );
}
