import {
  Box,
  Stack,
  Autocomplete,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  MenuItem,
} from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import CustomInput from "../CustomInput";
import CustomText from "../CustomText";
import { useEffect, useState } from "react";
import useThemeStore from "src/store/themeStore";
import { SearchMarketing } from "src/api/user";
import { useDebouncedCallback } from "use-debounce";
import CustomButton from "../CustomButton";
import { useLoadingStore } from "src/store/loadingStore";
import { getCurrentTime } from "src/utils/formatter";
import { checkWorkingDay, CreateTaskWorker, UpdateItemLog } from "src/api/item";
import { useLocation } from "react-router-dom";
import { priorityLevel } from "src/utils/paramData";
import ModalConfirm from "../ModalConfirm";
import InputDate from "../Datepicker";
import NotificationAlert from "../Alert";

interface Props {
  refetch: () => void;
}
export default function PreMarketing({ refetch }: Props) {
  const { theme } = useThemeStore();
  const { showNotification } = useLoadingStore();
  const id = useLocation().pathname.split("/").pop();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [data, setData] = useState<any>({
    man_days: 3,
  });
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [workDay, setWorkDay] = useState<any>({});

  const fetchSearchAssignee = async (key: string) => {
    if (key === "" || key === null || key === undefined) {
      return;
    }

    setIsLoading(true);
    const response = await SearchMarketing();
    response?.data ? setAssigneeList(response?.data) : setAssigneeList([]);
    setIsLoading(false); // Reset loading state after API call
  };

  const debouncedFetch = useDebouncedCallback(fetchSearchAssignee, 1000);

  const handleInputChange = (newInputValue: string) => {
    debouncedFetch(newInputValue);
  };

  const handleSelect = (event: any, newValue: any | null) => {
    setSelectedUser(newValue);
    setData((prevData: any) => ({ ...prevData, ["assignee"]: newValue?.id }));
  };

  const filterOptions = (options: any[], { inputValue }: any) => {
    return options.filter((option: { username: string }) =>
      option.username.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleCheckWorkingDay = async (data: any) => {
    const response = await checkWorkingDay(data);
    setWorkDay(response?.data);
  };

  useEffect(() => {
    if (selectedUser?.id && data?.man_days) {
      const req = {
        user_id: selectedUser?.id,
        man_days: data?.man_days,
      };
      handleCheckWorkingDay(req);
    }
  }, [selectedUser?.id, data?.man_days]);

  const handleSubmit = async () => {
    const form = document.querySelector("form");
    if (!form) return;

    const formData = new FormData(form);

    if (Number(data?.man_days) <= 0) {
      return NotificationAlert({
        message: "Main days must be greater than 0.",
        status: "error",
      });
    }

    // if (!selectedUser?.id) {
    //   return NotificationAlert({
    //     message: "You must assign to a user.",
    //     status: "error",
    //   });
    // }

    const req: CreateTask = {
      user_id: selectedUser?.id,
      item_id: id!,
      priority: formData?.get("priority")?.toString() || priorityLevel[0].value,
      type_task: "MARKETING-PRE",
      main_day: Number(data?.man_days),
      cost: 0,
    };
    await CreateTaskWorker(req);
    await refetch();
    setOpenModalCreate(false);
  };

  const handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModalCreate(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  return (
    <Box component={"form"} onSubmit={handleFormSubmit}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}>
        <CustomText variant="body1" fontWeight={"bold"}>
          Create Task Marketing Pre-Production
        </CustomText>
        <CustomText variant="body2">{getCurrentTime()}</CustomText>
      </Stack>
      <Stack direction={"row"} mt={2} gap={2}>
        <Stack width={"30%"}>
          <CustomText variant="body1">Priority</CustomText>
          <CustomInput
            fullWidth
            size="small"
            id="priority"
            name="priority"
            defaultValue={priorityLevel[0].value}
            select>
            {priorityLevel.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomInput>
        </Stack>
        <Stack width={"70%"}>
          <CustomText variant="body1">Assign To</CustomText>
          <Autocomplete
            options={assigneeList}
            noOptionsText="Worker Not Found"
            fullWidth
            autoComplete
            getOptionLabel={(option: { username: string; email: string }) =>
              option?.username
            }
            isOptionEqualToValue={(option, value) =>
              option.username === value.username
            }
            filterOptions={filterOptions}
            loading={isLoading}
            open={open}
            value={selectedUser}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={handleSelect}
            onInputChange={(_, newInputValue) =>
              handleInputChange(newInputValue)
            }
            id="assignee"
            componentName="assignee"
            renderInput={(params) => (
              <CustomInput
                {...params}
                id="assignee"
                name="assignee"
                placeholder="Search Assignee"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component={"li"}
                {...props}
                sx={{
                  backgroundColor: theme?.bgSecondary,
                  color: theme?.inputText,
                  "&.MuiAutocomplete-option.Mui-focused": {
                    backgroundColor: theme?.bgPrimary,
                  },
                }}>
                <List sx={{ width: "100%" }}>
                  <ListItem>
                    <ListItemText
                      primary={<CustomText>{option?.username}</CustomText>}
                      secondary={
                        <Fragment>
                          <CustomText>{option?.email}</CustomText>
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <Divider sx={{ borderBottomWidth: 4 }} />
                </List>
              </Box>
            )}
          />
        </Stack>
      </Stack>

      <Stack mt={2} direction={"row"} gap={2}>
        <Stack width={"40%"}>
          <CustomText variant="body1">Start Date</CustomText>
          <InputDate
            disabled
            id="start_date"
            name="start_date"
            onChange={handleChange}
            value={workDay?.ActualStartDate}
          />
        </Stack>
        <Stack width={"40%"}>
          <CustomText variant="body1">End Date</CustomText>
          <InputDate
            disabled
            id="end_date"
            name="end_date"
            onChange={handleChange}
            value={workDay?.EndDate}
          />
        </Stack>
        <Stack width={"20%"}>
          <CustomText variant="body1">Man Days</CustomText>
          <CustomInput
            required
            size="small"
            id="man_days"
            name="man_days"
            onChange={handleChange}
            value={data?.man_days}
          />
        </Stack>
        {/* <Stack width={"50%"}>
          <CustomText variant="body1">Task Type</CustomText>
          <CustomInput
            required
            disabled
            size="small"
            id="task_point"
            name="task_point"
            value={"MARKETING-PRE"}
          />
        </Stack> */}
      </Stack>
      <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
        <CustomButton variant="contained" type="submit">
          Submit
        </CustomButton>
      </Box>
      <ModalConfirm
        text="Are you sure you want to create task?"
        open={openModalCreate}
        onClose={() => setOpenModalCreate(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
