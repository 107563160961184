import { Box, Button, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import colors from "src/themes/colors";
import InsentiveForms from "../../components/InsentiveForms";
import CustomButton from "src/components/main/CustomButton";
import CustomCard from "src/components/main/CustomCard";
import CustomDropbox from "src/components/main/CustomDropbox";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import InputDate from "src/components/main/Datepicker";
import NewInputNominal from "src/components/main/NewInputNominal";
import { getItemLogAttachment, getItemLogAttachmentGroup } from "src/api/item";
import ActionDisbursement from "../../components/ActionDisbursement";
import { useLoadingStore } from "src/store/loadingStore";
import { dA } from "@fullcalendar/core/internal-common";

interface Props {
  data: any;
}

export default function InsentiveDetail({ data }: Props) {
  const [attachment, setAttachment] = useState<Attachment[]>([]);
  const [openDisbursment, setOpenDisbursment] = useState<boolean>(false);
  const [disburseList, setDisburseList] = useState<any>([]);
  const [checkedData, setCheckedData] = useState<any>([]);
  const { showNotification } = useLoadingStore.getState();
  const handleAction = () => {

    const  items: any[] = [];
      items.push(data);      
      setCheckedData(items);
      setOpenDisbursment(true);
  };


  const onClose = () => {
    setOpenDisbursment(false);
    fetchAttachment();
  };


  const fetchAttachment = async () => {
    const response = await getItemLogAttachmentGroup(data?.attachment);
    setAttachment(response?.data);
  };

  useEffect(() => {
    if (data?.attachment) {
      fetchAttachment();
    }
  }, [data?.attachment]);

  return (
    <CustomCard text="Insentive Detail">
      <Grid container columnSpacing={4}>
        <Grid item sm={4}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Period</CustomText>
            <CustomInput
              disabled
              size="small"
              id="year_month"
              name="year_month"
              value={data?.year_month || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Status</CustomText>
            <CustomInput
              disabled
              size="small"
              id="status"
              name="status"
              value={data?.status || ""}
            />
          </Stack>
        </Grid>
        <Grid item sm={4}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Employee Name</CustomText>
            <CustomInput
              disabled
              size="small"
              id="nama_worker"
              name="nama_worker"
              value={data?.nama_worker || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Disbursed Date</CustomText>
            <InputDate
              disabled
              id="disburse_date"
              name="disburse_date"
              value={data?.disburse_date?.split("T")[0] || ""}
            />
          </Stack>
        </Grid>
        <Grid item sm={4}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Role</CustomText>
            <CustomInput
              disabled
              size="small"
              id="role_name"
              name="role_name"
              value={data?.role_name || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Amount</CustomText>
            <NewInputNominal
              disabled
              size="small"
              id="total_cost"
              name="total_cost"
              value={data?.total_cost?.toString()}
            />
          </Stack>
        </Grid>
      </Grid>
      <Box mt={2}>
        <CustomDropbox
          existingFiles={attachment}
          viewOnly={true}
          onDelete={() => {}}
          onUpload={() => {}}
        />
      </Box>
      <Grid container>
          <Grid item lg={12} md={12} sm={12}></Grid>{" "}
          {/* First empty Grid item */}
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            justifyContent="flex-end">
            {/* Aligns content to the right */}
            { data?.status === "Pending" && (
              <Stack width={"20%"}>
              <CustomButton
                variant="contained"
                onClick={handleAction}>
                Disburse Incentive
              </CustomButton>
            </Stack>
            )}
          </Grid>
          <ActionDisbursement
        isOpen={openDisbursment}
        onClose={onClose}
        data={checkedData}
        refetch={fetchAttachment}
      />
        </Grid>
    </CustomCard>
  );
}
