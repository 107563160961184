import { Box, Modal, Stack } from "@mui/material";
import CustomDivider from "src/components/main/CustomDivider";
import CustomText from "src/components/main/CustomText";
import DifferenceIcon from "@mui/icons-material/Difference";
import CustomTable from "src/components/main/CustomTable";
import { DecimalFormatter } from "src/utils/formatter";
import { useEffect, useState } from "react";
import CustomButton from "src/components/main/CustomButton";
import CustomPaper from "src/components/main/CustomPaper";
import { disburseReimbursement } from "src/api/reimburse";
import ModalConfirm from "src/components/main/ModalConfirm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: Reimbursement[];
  refetch: () => void;
}

export default function ActionReimbursement(props: Props) {
  const [openModal, setOpenModal] = useState(false);
  const { isOpen, onClose, data, refetch } = props;

  const totalPrice = data?.reduce((accumulator, item) => {
    return accumulator + (item.cost || 0);
  }, 0);

  const formatData = () => {
    const processedData = data?.map((item: any, index: number) => {
      return {
        ...item,
        no: index + 1,
        cost: `Rp ${DecimalFormatter(item?.cost?.toString())}`,
        claim_date: item?.claim_date?.split("T")[0],
      };
    });

    return processedData;
  };

  const handleSubmit = async () => {
    const req: DisburseReimbursement = {
      reimburse_id: data?.map((item) => item.reimburse_id),
      payment_method: "Bank Transfer",
      payment_currency: "IDR",
    };
    const response = await disburseReimbursement(req);
    if (response?.status === 200) {
      setOpenModal(false);
      onClose();
      await refetch();
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <CustomPaper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          height: "auto",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ width: "100%", flexGrow: 1 }}>
          <Box>
            <Stack direction={"row"} gap={2}>
              <DifferenceIcon style={{ color: "#fff" }} fontSize="large" />
              <CustomText variant="h6" gutterBottom>
                Reimbursement
              </CustomText>
            </Stack>
            <CustomDivider sx={{ mt: 1 }} />
            <Box sx={{ py: 2 }}>
              <Box>
                <CustomTable
                  columns={columns}
                  data={formatData()}
                  pagination={false}
                  stickyHeader
                  sx={{ maxHeight: "50vh" }}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={2}
                gap={6}
              >
                <Stack>
                  <CustomText variant="h6" gutterBottom fontWeight={"bold"}>
                    {`${data?.length} data selected`}
                  </CustomText>
                </Stack>
                <Stack>
                  <CustomText variant="h6" gutterBottom fontWeight={"bold"}>
                    Total Shipment Price :&emsp;
                    {`Rp ${DecimalFormatter(totalPrice?.toLocaleString())}`}
                  </CustomText>
                </Stack>
              </Box>
              <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
                <CustomButton
                  variant="contained"
                  onClick={() => setOpenModal(true)}
                >
                  Reimburse
                </CustomButton>
              </Box>
            </Box>
          </Box>
          <ModalConfirm
            text="Are you sure to do this action?"
            open={openModal}
            onClose={() => setOpenModal(false)}
            onSubmit={handleSubmit}
          />
        </Box>
      </CustomPaper>
    </Modal>
  );
}

const columns = [
  { title: "No", field: "no", sortable: false, minWidth: 50 },
  { title: "Expense ID", field: "ship_id", sortable: false, minWidth: 100 },
  {
    title: "Item Name",
    field: "reimburse_name",
    sortable: false,
    minWidth: 250,
  },
  { title: "User Name", field: "user_name", sortable: false },
  { title: "Expedition", field: "expedtition", sortable: false, minWidth: 100 },
  {
    title: "Price",
    field: "cost",
    sortable: false,
    align: "right",
    minWidth: 100,
  },
  { title: "Date", field: "claim_date", sortable: false },
];
