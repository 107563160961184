import {
  Box,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import { DropBox } from "../Dropbox";
import { useState, useCallback } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import useThemeStore from "src/store/themeStore";
import ImageHolder from "./ImageHolder";
import { base64ToURL } from "src/utils/decoder";
import NotificationAlert from "../Alert";

type TFile = "url" | "base64";
interface Props {
  id?: string;
  viewOnly?: boolean;
  files?: File[] | object[];
  filesType?: TFile;
  existingFiles?: Attachment[];
  filesLimit?: number;
  size?: number;
  deleteByIndex?: boolean;
  onDelete?: (fileName: string) => void;
  onDeleteByIndex?: (index: number) => void;
  onUpload?: (acceptedFiles: File[]) => void;
}

export default function CustomDropbox(props: Props) {
  const {
    id = "dropbox",
    viewOnly = false,
    files = [],
    filesType = "url",
    existingFiles = [],
    filesLimit = 5,
    size = 200,
    deleteByIndex = false,
    onUpload = () => {},
    onDelete = () => {},
    onDeleteByIndex = () => {},
  } = props;

  const { theme } = useThemeStore();

  // Allowed file types for security purposes
  const allowedTypes = ["image/jpeg", "image/png"];

  // Handle file upload with type validation
  const handleUpload = (acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (validFiles.length === 0) {
      NotificationAlert({
        message: "Only JPG, JPEG, PNG, PDF, and DOC files are wed.allo",
        status: "error",
      });

      return;
    }

    onUpload(validFiles);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-start"}
      flexDirection={"row"}
      width={"100%"}>
      <ImageHolder size={size} image={existingFiles} />
      {files?.length > 0 &&
        files?.map((item: any, index: number) => (
          <ImageListItem
            key={index}
            sx={{
              marginRight: 2,
              width: size,
              height: size,
              borderRadius: 2,
              overflow: "hidden",
            }}>
            <img
              src={
                filesType === "url"
                  ? URL.createObjectURL(item) ||
                    "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"
                  : base64ToURL(item?.bukti_payment_base64)
              }
              loading="lazy"
            />
            {!viewOnly && (
              <ImageListItemBar
                position="top"
                actionIcon={
                  <IconButton
                    sx={{ color: theme?.inputLabel }}
                    aria-label={`info about ${item.title}`}>
                    <DeleteIcon
                      onClick={
                        deleteByIndex
                          ? () => onDeleteByIndex(index)
                          : () => onDelete(item?.name)
                      }
                    />
                  </IconButton>
                }
                actionPosition="left"
              />
            )}
          </ImageListItem>
        ))}
      {!viewOnly && files?.length + existingFiles?.length < filesLimit ? (
        <DropBox
          sx={{ width: size, height: size }}
          onDrop={handleUpload}
          id={id}
        />
      ) : null}
    </Box>
  );
}
