import { Box, Divider } from "@mui/material";
import CustomTabPanel from "src/components/main/CustomTabPanel";
import ReportIncome from "./components/ReportIncome";
import ReportOutcome from "./components/ReportOutcome";
import CustomTabs, { CustomTab } from "src/components/main/CustomTabs";
import PageTitle from "src/components/main/CustomText/PageTitle";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getFinanceDetail } from "src/api/finance";
import { DecimalFormatter } from "src/utils/formatter";
import { getCurrencySymbol } from "src/utils/paramData";

export default function FinanceReportDetail() {
  const [value, setValue] = useState<number>(0);
  const [incomeData, setIncomeData] = useState<FinanceDetailIncome>(
    {} as FinanceDetailIncome
  );
  const [expenseData, setExpenseData] = useState<FinanceDetailExpense>(
    {} as FinanceDetailExpense
  );

  const navigate = useNavigate();
  const id = useLocation().pathname.split("/").pop();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    const response = await getFinanceDetail(id!);
    if (response?.status !== 200) {
      navigate("/finance/monthly-recap");
      return;
    }

    const processedIncomeData = formatIncomeData(
      response?.data?.data?.incom_list
    );

    const processedExpenseData = formatExpenseData(
      response?.data?.data?.expense_list
    );

    // Set income data
    setIncomeData({
      incom_list: processedIncomeData,
      summary: response?.data?.data?.summary,
      debt_summary: response?.data?.data?.debt_summary,
    });
    // Set expense data
    setExpenseData({
      expense_list: processedExpenseData,
      expens_summary: response?.data?.data?.expens_summary?.DataSummary,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatIncomeData = (data: FinanceDetailIncomeList[]) => {
    return data?.map((item: FinanceDetailIncomeList) => {
      return {
        ...item,
        date: item?.payment_date?.split("T")[0],
        order_date: item?.order_date?.split("T")[0],
        amount: `${getCurrencySymbol(item?.currency)} ${DecimalFormatter(
          item?.amount
        )}`,
      };
    });
  };

  const formatExpenseData = (data: FinanceDetailExpenseList[]) => {
    return data?.map((item: FinanceDetailExpenseList) => {
      return {
        ...item,
        tanggal: item?.tanggal?.split("T")[0],
        amount: `Rp ${DecimalFormatter(item?.cost?.toString())}`,
      };
    });
  };

  return (
    <Box>
      <PageTitle>{`Monthly Report Detail ${id}`}</PageTitle>
      <Divider />
      <CustomTabs value={value} onChange={handleChange}>
        <CustomTab label="Income" />
        <CustomTab label="Expense" />
      </CustomTabs>
      <CustomTabPanel value={value} index={0}>
        <ReportIncome data={incomeData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ReportOutcome data={expenseData} />
      </CustomTabPanel>
    </Box>
  );
}
