import { Box, CircularProgress, Stack } from "@mui/material";
import ILogo from "src/assets/ilogo.png";

interface Props {
  message?: string;
}

export default function LoadingAtom({ message = "Loading ..." }: Props) {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
      }}>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        }}>
        <img
          srcSet={`${ILogo}?w=248&fit=crop&auto=format&dpr=2 2x`}
          src={`${ILogo}?w=248&fit=crop&auto=format`}
          alt={ILogo}
          loading="lazy"
          width={"5%"}
        />
        <CircularProgress size={40} />
        {/* <Player
          autoplay
          loop
          src={ILogo}
          style={{ height: "300px", width: "300px" }}
        /> */}
        {/* <AtomicSpinner /> */}
        {/* {message && (
          <CustomText variant="body1" textAlign={"center"}>
            {message}
          </CustomText>
        )} */}
      </Stack>
    </Box>
  );
}
