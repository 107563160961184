import { Box, Grid, InputAdornment, MenuItem, Stack } from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import NewInputNominal from "src/components/main/NewInputNominal";
import { Ekspedisi, currencies, getCurrencySymbol } from "src/utils/paramData";
import CustomCard from "src/components/main/CustomCard";
import CustomDropbox from "src/components/main/CustomDropbox";
import UserAutocomplete from "src/components/main/CustomAutocomplete/UserAutocomplete";
import ImageHolder from "src/components/main/CustomDropbox/ImageHolder";
import { useEffect, useState } from "react";
import { getItemLogAttachmentGroup } from "src/api/item";

interface Props {
  disabled?: boolean;
  data: PurchaseShipment | RequestAddPurchaseShipment;
  onChange: (e: any) => void;
  onSubmit: (e: any) => void;
  onUpload: (files: File[]) => void;
  onDelete: (index: number) => void;
}

export default function ShipmentForm({
  disabled = false,
  data,
  onChange,
  onSubmit,
  onUpload,
  onDelete,
}: Props) {
  const [attachment, setAttachment] = useState<Attachment[]>([]);

  useEffect(() => {
    if (data?.type !== "RequestAddPurchaseShipment" && data?.resi_attachment) {
      fetchAttachment(data?.resi_attachment);
    }
  }, [data]);

  const fetchAttachment = async (id: string) => {
    const response = await getItemLogAttachmentGroup(id);
    setAttachment(response?.data);
  };

  return (
    <Box component={"form"} onSubmit={onSubmit}>
      <CustomCard text="Shipment Data">
        <Grid container columnSpacing={4}>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!disabled}>
                User Name
              </CustomText>
              {data?.type === "RequestAddPurchaseShipment" ? (
                <UserAutocomplete />
              ) : (
                <CustomInput
                  onChange={onChange}
                  disabled={disabled}
                  size="small"
                  id="name"
                  name="name"
                  value={data?.display_name || ""}
                  required
                />
              )}
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!disabled}>
                Expedition
              </CustomText>
              <CustomInput
                disabled={disabled}
                size="small"
                id="expedition"
                name="expedition"
                select
                onChange={onChange}
                value={
                  data?.expedition ? data?.expedition : Ekspedisi[0].value
                }>
                {Ekspedisi.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!disabled}>
                Receipt
              </CustomText>
              <CustomInput
                disabled={disabled}
                size="small"
                id="resi"
                name="resi"
                onChange={onChange}
                value={data?.resi || ""}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Order ID</CustomText>
              <CustomInput
                onChange={onChange}
                disabled={disabled}
                size="small"
                id="order_id"
                name="order_id"
                value={data?.order_id || ""}
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!disabled}>
                Currency
              </CustomText>
              <CustomInput
                required
                disabled={disabled}
                onChange={onChange}
                size="small"
                id="currency"
                name="currency"
                select
                value={data?.currency ? data?.currency : currencies[0].value}>
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Item ID</CustomText>
              <CustomInput
                onChange={onChange}
                disabled={disabled}
                size="small"
                id="item_id"
                name="item_id"
                value={data?.item_id || ""}
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!disabled}>
                Shipping Price
              </CustomText>
              <NewInputNominal
                required
                disabled={disabled}
                size="small"
                id="cost"
                name="cost"
                value={data?.cost?.toString() || ""}
                onChange={onChange}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(data?.currency)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack my={"10px"}>
          <CustomText variant="body1">Notes</CustomText>
          <CustomInput
            onChange={onChange}
            disabled={disabled}
            size="small"
            id="notes"
            name="notes"
            multiline
            rows={4}
            value={data?.notes || ""}
          />
        </Stack>
        <Stack mt={2}>
          <CustomText variant="body1" gutterBottom required={!disabled}>
            Attachment
          </CustomText>
        </Stack>

        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          gap={2}
          flexDirection={"row"}
          maxHeight={"15%"}>
          {data?.type === "RequestAddPurchaseShipment" && (
            <CustomDropbox
              files={data?.resi_attach}
              filesType={"base64"}
              onUpload={onUpload}
              deleteByIndex={true}
              onDeleteByIndex={onDelete}
            />
          )}
          {attachment && <ImageHolder image={attachment} size={100} />}
        </Box>
      </CustomCard>
      {!disabled && (
        <Box
          mt={2}
          display={"flex"}
          justifyContent={"flex-end"}
          gap={2}
          flexDirection={"row"}>
          <CustomButton variant="contained" type="submit">
            Submit
          </CustomButton>
        </Box>
      )}
    </Box>
  );
}
