import { Box, Card, Grid } from "@mui/material";
import CustomTable from "../../../components/main/CustomTable";
import { useNavigate } from "react-router-dom";
import FilterTable from "src/components/main/Filter";
import CustomText from "src/components/main/CustomText";
import { useOrderStore } from "src/store/orderStore";
import { useEffect, useState } from "react";
import { InquiryOrderList } from "src/api/order";
import NotificationAlert from "src/components/main/Alert";
import { useFilterStore } from "src/store/filterStore";
import AddIcon from "@mui/icons-material/Add";
import { DecimalFormatter, getDayLeft } from "src/utils/formatter";
import { getCurrencySymbol } from "src/utils/paramData";
import CustomButton from "src/components/main/CustomButton";
import useThemeStore from "src/store/themeStore";
import PageTitle from "src/components/main/CustomText/PageTitle";
import FilterSearch from "src/components/main/Filter/FilterSearch";
import { ORDER_COLUMNS, ORDER_STATUSES } from "src/constants/order";

function OrderList() {
  const { theme } = useThemeStore();
  const [data, setData] = useState<any>([]);
  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const { resetOrderStore } = useOrderStore();
  const { resetFilterStore } = useFilterStore();
  const navigate = useNavigate();

  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await InquiryOrderList({
        status: filter.status,
        id: filter.key,
        order_key: filter.order_key,
        sort_type: filter.sort_type,
        start_date: filter.start_date,
        end_date: filter.end_date,
        page: filter.page,
        limit: filter.limit,
        created_at: {
          start_date: filter.order_date_start,
          end_date: filter.order_date_end,
        },
      });
      response?.data?.totalData
        ? formatData(response?.data?.list)
        : setData([]);
      handleFilterChange("total_page", response?.data?.totalPage);
      response?.data?.totalPage < filter.page && handleFilterChange("page", 1);
    } catch (error: any) {
      console.error("Error fetching order list:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    filter.status,
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
    filter.order_date_start,
    filter.order_date_end,
  ]);

  useEffect(() => {
    resetOrderStore();
    return () => {
      resetOrderStore();
      resetFilterStore();
    };
  }, []);

  const onClickDetail = (id: string) => {
    navigate(`/order/${id}`);
  };

  const formatData = (data: any) => {
    const processedData = data?.map((item: any) => {
      const dayLeft = getDayLeft(item?.deadline);
      return {
        ...item,
        created_at: item?.created_at?.split("T")[0],
        deadline:
          dayLeft > 0
            ? `${dayLeft} days left`
            : dayLeft === 0
            ? "Due Today"
            : `${Math.abs(dayLeft)} days overdue`,
        amount: `${getCurrencySymbol(item?.currency)} ${DecimalFormatter(
          item?.amount?.toString()
        )}`,
      };
    });

    setData(processedData);
  };

  return (
    <Box>
      <PageTitle>Order</PageTitle>
      <Grid container columnSpacing={2}>
        <Grid item sm={2}>
          <FilterTable
            statuses={ORDER_STATUSES}
            filter={filter}
            onChangeFilter={handleFilterChange}
          />
        </Grid>
        <Grid item sm={10}>
          <Card>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}
            >
              <Box sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Order List
                </CustomText>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
                <FilterSearch onChangeFilter={handleFilterChange}/>
                <CustomButton
                  variant="contained"
                  onClick={() => navigate("/order/create")}
                  startIcon={<AddIcon />}
                >
                  Create Order
                </CustomButton>
              </Box>
            </Box>
            <CustomTable
              columns={ORDER_COLUMNS}
              data={data}
              sortable={false}
              onClickDetail={onClickDetail}
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default OrderList;
