import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomText from "src/components/main/CustomText";
import useThemeStore from "src/store/themeStore";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useState } from "react";
import { GetDashboarNeedAction } from "src/api/dashboard";
import NotificationAlert from "src/components/main/Alert";
import { sortByProperty } from "src/utils/utils";
import CustomDivider from "src/components/main/CustomDivider";
import CardNeedActionHeader from "./CardNeedActionHeader";
import CustomCard from "src/components/main/CustomCard";
import { useFilterStore } from "src/store/filterStore";
import { useNavigate } from "react-router-dom";

const ItemNeedAction = ({
  itemData,
}: {
  itemData: {
    role: string;
    data: {
      step: string;
      total: number;
    }[];
  };
}) => {
  const { theme } = useThemeStore();
  const navigate = useNavigate();
  const { setStatus } = useFilterStore();
  const handleOnClick = (status: string) => {
    setStatus([status]);
    navigate("/item");
  };
  return (
    <CustomCard
      text={itemData?.role}
      sx={{
        my: 0,
        height:
          itemData?.role === "supplier" || itemData?.role === "Supplier Mobile"
            ? "65%"
            : "100%",
      }}>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  border: "none",
                  padding: "0px 20px",
                  color: theme?.inputLabel,
                  backgroundColor: theme?.bgSecondary,
                }}>
                <CustomText variant="body1" margin={"5px"} fontWeight={700}>
                  Status
                </CustomText>
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                  padding: "0px 20px 0px 0px",
                  color: theme?.inputLabel,
                  backgroundColor: theme?.bgSecondary,
                }}>
                <CustomText variant="body1" margin={"5px"} fontWeight={700}>
                  Total
                </CustomText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemData?.data?.map((item, index) => {
              return (
                <TableRow
                  key={index}
                  className="hover:opacity-50 cursor-pointer"
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "rgba(255,255,255, 0.1)" },
                  }}
                  onClick={() => handleOnClick(item?.step)}>
                  <TableCell style={{ border: "none", padding: "0px 20px" }}>
                    <CustomText variant="body1" margin={"5px"} fontWeight={400}>
                      {item?.step}
                    </CustomText>
                  </TableCell>
                  <TableCell style={{ border: "none", padding: "0px 20px" }}>
                    <CustomText variant="body1" margin={"5px"} fontWeight={400}>
                      {item?.total}
                    </CustomText>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </CustomCard>
  );
};

function CardNeedAction() {
  const { theme } = useThemeStore();

  const [data, setData] = useState<any>([]);
  const [dueDate, setDueDate] = useState<any>("");

  const filterData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "due_date") {
      setDueDate(value);
    }
  };

  const debouncedFilter = useDebouncedCallback(filterData, 1000);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e);
  };

  useEffect(() => {
    fetchData();
  }, [dueDate]);

  const fetchData = async () => {
    try {
      const response = await GetDashboarNeedAction(dueDate);
      response !== null && setData(sortByProperty(response?.data, "role"));
    } catch (error: any) {
      console.error("Error fetching dashboard need action:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  return (
    <Box mt={6} mb={2}>
      {/* <CardNeedActionHeader /> */}
      <Box height={650}>
        <Grid container spacing={2} height={"90%"}>
          <Grid item xs={12} md={4}>
            <Box height={"100%"}>
              <ItemNeedAction
                itemData={data?.find(
                  (item: any) => item?.role === "User Admin"
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              gap={2}>
              <ItemNeedAction
                itemData={data?.find(
                  (item: any) => item?.role === "Worker Mobile"
                )}
              />
              <ItemNeedAction
                itemData={data?.find(
                  (item: any) => item?.role === "Logistic Mobile"
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              gap={2}>
              <ItemNeedAction
                itemData={data?.find(
                  (item: any) => item?.role === "Marketing Mobile"
                )}
              />
              <ItemNeedAction
                itemData={data?.find(
                  (item: any) => item?.role === "Supplier Mobile"
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Grid container spacing={"24px"}>
          {data?.map((item: any, index: number) => {
            return (
              <Grid key={index} item xs={12} md={4}>
                <ItemNeedAction itemData={item} />
              </Grid>
            );
          })}
        </Grid> */}
    </Box>
  );
}

export default CardNeedAction;
