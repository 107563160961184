export const TASK_LIST_COLUMNS = [
  { sortable: true, title: "Task ID", field: "id" },
  { sortable: true, title: "Item ID", field: "item_id" },
  { sortable: true, title: "Assign To", field: "display_name" },
  { sortable: true, title: "Due Date", field: "end_date" },
  { sortable: false, title: "Deadline", field: "deadline" },
  { sortable: true, title: "Role", field: "role_name" },
  { sortable: true, title: "Task Points", field: "level_worker" },
  { sortable: true, title: "Status", field: "status" },
  { sortable: true, title: "Design", field: "design_name" },

];

export const TASK_STATUSES = [
  "Task In Progress",
  "Waiting For Approval",
  "Revision",
  "Done",
  "Waiting Receipt from Logistic",
  "Waiting to be Ship",
  "Waiting Receipt from Worker",
  "On Delivery to Worker",
  "On Delivery to Tangerang",
];
