import { Box, Divider, Stack } from "@mui/material";
import { getCurrentTime } from "src/utils/formatter";
import { formatPhoneNumber } from "src/utils/utils";
import CustomButton from "../CustomButton";
import CustomInput from "../CustomInput";
import CustomPaper from "../CustomPaper";
import CustomText from "../CustomText";
import InputDate from "../Datepicker";
import { useState, useEffect } from "react";
import {
  getItemLogAttachment,
  getItemLogAttachmentGroup,
  getWAMessageMarketing,
} from "src/api/item";
import { useLoadingStore } from "src/store/loadingStore";
import ModalConfirm from "../ModalConfirm";
import { approveTask, reviseTask } from "src/api/task";
import TaskPoint from "../TaskPoint";
import ImageHolder from "../CustomDropbox/ImageHolder";
import CustomDivider from "../CustomDivider";

interface Props {
  refetch: () => void;
  taskData: any;
  taskAttachment?: string;
  logisticQCAttachment?: Attachment[];
}
export default function AdminApproveTask({
  logisticQCAttachment,
  taskData,
  refetch,
}: Props) {
  const [isRevision, setIsRevision] = useState<boolean>(false);
  const [openModalRevision, setOpenModalRevision] = useState<boolean>(false);
  const [photo, setPhoto] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const [message, setMessage] = useState<any>({});
  const [openModalApprove, setOpenModalApprove] = useState<boolean>(false);

  const fetchWAMessage = async () => {
    const res = await getWAMessageMarketing(taskData?.id);
    setMessage(res?.data || {});
  };

  const fetchAttachment = async () => {
    const res = await getItemLogAttachmentGroup(taskData?.task_attachment);
    setPhoto(res?.data);
  };

  useEffect(() => {
    fetchWAMessage();
    fetchAttachment();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const calculateTotalDays = () => {
      if (data?.end_date && data?.start_date) {
        const totalDays =
          Math.floor(
            (new Date(data.end_date).getTime() -
              new Date(data.start_date).getTime()) /
              (1000 * 60 * 60 * 24)
          ) + 1;
        setData((prevData: any) => ({
          ...prevData,
          main_day: totalDays,
        }));
      }
    };

    calculateTotalDays();
  }, [data?.end_date, data?.start_date]);

  const handleApprove = async () => {
    const req: TaskApproval = {
      task_id: taskData?.id,
      is_completed: taskData?.id.startsWith("M") ? true : false,
    };
    await approveTask(req);
    await refetch();
    setOpenModalApprove(false);
  };

  const handleRevision = async () => {
    const req: TaskRevision = {
      task_id: taskData?.id,
      main_day: parseInt(data?.main_day),
      type_task: taskData?.id?.startsWith("R")
        ? "RECONER"
        : taskData?.id?.startsWith("MP")
        ? "MARKETING-PRE"
        : data?.type_task,
      ...data,
    };
    await reviseTask(req);
    await refetch();
    setData({});
    setIsRevision(false);
  };

  return (
    <CustomPaper sx={{ minWidth: "50%", width: "fit-content", p: 2 }}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
          Task Approval By Admin {taskData?.id ? `(${taskData?.id})` : ""}
        </CustomText>
        <CustomText variant="body2">{getCurrentTime()}</CustomText>
      </Stack>
      <Stack mt={2} direction={"row"} gap={2}>
        <Stack>
          <CustomText variant="body1">Task Documentation</CustomText>
          <ImageHolder image={photo} size={100} />
        </Stack>
        {logisticQCAttachment && logisticQCAttachment.length > 0 && (
          <>
            <Divider
              sx={{ mt: 2 }}
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <Stack>
              <CustomText variant="body1">Logistic QC Documentation</CustomText>
              <ImageHolder image={logisticQCAttachment || []} size={100} />
            </Stack>
          </>
        )}
      </Stack>
      {isRevision && (
        <Stack width={"100%"} mt={2}>
          <Stack direction={"row"} gap={2}>
            <Stack>
              <CustomText variant="body1">Start Date</CustomText>
              <InputDate
                id="start_date"
                name="start_date"
                onChange={handleChange}
                value={data?.start_date}
              />
            </Stack>
            <Stack>
              <CustomText variant="body1">End Date</CustomText>
              <InputDate
                id="end_date"
                name="end_date"
                onChange={handleChange}
                defaultValue={data?.start_date}
                disablePast
                value={data?.end_date}
              />
            </Stack>
            <Stack>
              <CustomText variant="body1">Man Days</CustomText>
              <CustomInput
                required
                disabled
                size="small"
                id="main_day"
                name="main_day"
                onChange={handleChange}
                value={data?.main_day}
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} mt={2} gap={2}>
            <Stack width={"30%"}>
              <CustomText variant="body1">Task Point</CustomText>
              {taskData?.id.startsWith("R") ? (
                <CustomInput
                  disabled
                  size="small"
                  id="type_task"
                  name="type_task"
                  value={"RECONER"}
                />
              ) : taskData?.id.startsWith("MP") ? (
                <CustomInput
                  disabled
                  size="small"
                  id="type_task"
                  name="type_task"
                  value={"MARKETING-PRE"}
                />
              ) : (
                <TaskPoint
                  required
                  size="small"
                  select
                  id="type_task"
                  name="type_task"
                  value={data?.type_task}
                  onChange={handleChange}
                />
              )}
            </Stack>
            <Stack width={"70%"}>
              <CustomText variant="body1">Notes</CustomText>
              <CustomInput
                size="small"
                id="notes"
                name="notes"
                onChange={handleChange}
                value={data?.notes}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
      {!isRevision && (
        <Stack mt={2}>
          <CustomText variant="body1">Notes</CustomText>
          <CustomInput
            size="small"
            id="notes"
            name="notes"
            onChange={handleChange}
            value={data?.notes}
          />
        </Stack>
      )}
      <Box mt={2} display={"flex"} justifyContent={"flex-end"} gap={2}>
        {isRevision ? (
          <>
            <CustomButton
              variant="outlined"
              customType="cancel"
              onClick={() => setIsRevision(false)}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              disabled={data?.main_day < 1}
              onClick={() => setOpenModalRevision(true)}
            >
              Submit Revision
            </CustomButton>
          </>
        ) : (
          <>
            <CustomButton
              variant="contained"
              onClick={() => setIsRevision(true)}
            >
              Ask For Revision
            </CustomButton>

            <CustomButton
              variant="contained"
              onClick={() => setOpenModalApprove(true)}
            >
              Approve
            </CustomButton>
          </>
        )}
      </Box>
      <ModalConfirm
        text="Are you sure want to ask for revision for this item?"
        open={openModalRevision}
        onClose={() => setOpenModalRevision(false)}
        onSubmit={handleRevision}
      />
      <ModalConfirm
        text="Are you sure want to approve this task?"
        open={openModalApprove}
        onClose={() => setOpenModalApprove(false)}
        onSubmit={handleApprove}
      />
    </CustomPaper>
  );
}
