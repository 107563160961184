import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import {
  sendRequestGET,
  sendRequestPOST,
  sendRequestPUT,
} from "../sendRequest";
import NotificationAlert from "src/components/main/Alert";
import { HideLoading, ShowLoading } from "src/components/main/Loading";
import { useLoadingStore } from "src/store/loadingStore";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();

interface FilterDate {
  start_date: string;
  end_date: string;
}

interface ItemList {
  status?: Array<string>;
  start_date?: string;
  end_date?: string;
  id?: string;
  page: number;
  limit: number;
  order_key?: string;
  sort_type?: string;
  supplier_id?: string[];
  created_at?: FilterDate;
}

export const InquiryItemList = async (
  {
    status,
    start_date,
    end_date,
    id,
    page,
    limit,
    order_key,
    sort_type,
    supplier_id,
    created_at,
  }: ItemList = { page: 1, limit: 10 }
) => {
  const url = process.env.REACT_APP_ENDPOINT_ITEM_LIST || "";
  const userStore = useUserStore.getState();
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    status,
    start_date,
    end_date,
    id,
    page,
    limit,
    order_key,
    sort_type,
    supplier_id,
    created_at,
  };
  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION ITEM LIST", res);
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR ITEM LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const GetItemDetail = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_DETAIL || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION ITEM DETAIL", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR ITEM DETAIL", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const updateItemDetail = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_DETAIL || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPUT(url, data, header);
    console.info("ACTION UPDATE ITEM", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR UPDATE ITEM", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const GetItemLog = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_PROCESS || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    ShowLoading({});
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION GET ITEM LOG", res);
    HideLoading();
    return res.data;
  } catch (error: any) {
    HideLoading();
    console.error("ACTION ERROR GET ITEM LOG", error);
    NotificationAlert({ message: error.message, status: "error" });
    throw new Error(error);
  }
};

export const UpdateItemLog = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_LOG || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "multipart/form-data",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = new FormData();
  req.append("item_id", data.item_id);
  req.append("status", data.status);
  req.append("status_desc", data.status_desc);
  req.append("enum_status", data.enum_status);
  req.append("notes", data.notes);
  req.append("attachment", data.attachment);

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION UPDATE ITEM LOG", res);
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR UPDATE ITEM LOG", error);
    showNotification(error?.message, "error");
  } finally {
    hideLoading();
  }
};

export const DoUploadDesign = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_UPLOAD_DESIGN || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "multipart/form-data",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = new FormData();
  req.append("item_id", data.item_id);
  data.files.forEach((file: File) => {
    req.append("attachment", file, file.name);
  });

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION UPLOAD DESIGN ITEM", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR UPLOAD DESIGN ITEM", error);
    showNotification(error?.message, "error");
  } finally {
    hideLoading();
  }
};

export const StartBid = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_START_BIDDING || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    item_id: data?.item_id,
    start_offering_base: data?.price,
  };

  try {
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION START BIDDING ITEM", res);
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR START BIDDING ITEM", error);
    throw new Error(error);
  }
};

export const DoApproveRejectOffer = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_UPDATE_OFFER || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION APPROVE REJECT OFFER ITEM", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR APPROVE REJECT OFFER ITEM", error);
    showNotification(error?.message, "error");
  } finally {
    hideLoading();
  }
};

export const getItemLogAttachment = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_GET_ATTACHMENT || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION GET ITEM LOG ATTACHMENT", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET ITEM LOG ATTACHMENT", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getItemLogAttachmentGroup = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_GET_ATTACHMENT || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    const res = await sendRequestGET(`${url}/${id}/group`, header);
    console.info("ACTION GET ITEM LOG ATTACHMENT", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET ITEM LOG ATTACHMENT", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const UploadResiAdmin = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_ADMIN_RESI || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "multipart/form-data",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = new FormData();
  req.append("item_id", data?.item_id);
  req.append("resi", data?.nomor_resi);
  req.append("expedition", data?.expedition);
  req.append("base", data?.base);
  req.append("leather", data?.leather);
  data?.files.forEach((file: File) => {
    req.append("attachment", file, file.name);
  });

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION UPLOAD RESI ADMIN", res);
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR UPLOAD RESI ADMIN", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const checkWorkingDay = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CHECK_DAY_WORKER || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req: { user_id: string; duration: number } = {
    user_id: data?.user_id,
    duration: parseInt(data?.man_days),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION CHECK WORKING DAY", res);
    showNotification(res?.data?.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR CHECK WORKING DAY", error);
  } finally {
    hideLoading();
  }
};


export const checkWorkingDayCompany = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CHECK_DAY_COMPANY || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req: {  duration: number } = {
    duration: parseInt(data?.man_days),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION CHECK WORKING DAY", res);
    showNotification(res?.data?.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR CHECK WORKING DAY", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const CreateTaskWorker = async (data: CreateTask) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CREATE_TASK_PAINTER || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION CREATE TASK", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR CREATE TASK", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const middlePayment = async (data: ReqMiddlePayment) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_MIDDLE_PAYMENT || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION MIDDLE PAYMENT", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR MIDDLE PAYMENT", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getWAMessage = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_DESIGN_CLIENT_WA || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION GET WA MESSAGE", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET WA MESSAGE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getWAMessageMarketing = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_MARKETING_CLIENT_WA || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(`${url}/${id}`, {}, header);
    console.info("ACTION GET WA MESSAGE", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET WA MESSAGE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const itemQCAdminFromCustomer = async (data: CompleteStep) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_QC_ADMIN || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "multipart/form-data",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = new FormData();
  req.append("item_id", data?.item_id);
  req.append("status", data?.status);
  req.append("status_desc", data?.status_desc);
  req.append("enum_status", data?.enum_status);
  req.append("notes", data?.notes);
  data?.attachment.forEach((file: File) => {
    req.append("attachment", file, file.name);
  });
  data?.attachLeather.forEach((file: File) => {
    req.append("attachment-leather", file, file.name);
  });

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION ITEM QC FROM CUSTOMER", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR ITEM QC FROM CUSTOMER", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getMiddlePaymentData = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_MIDDLE_PAYMENT || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION GET MIDDLE PAYMENT DATA", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET MIDDLE PAYMENT DATA", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getRushData = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ORDER_RUSH_DATA || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION GET RUSH DATA", res);
    return res.data.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET RUSH DATA", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const rushItem = async (data: ReqItemRush) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ORDER_RUSH_ITEM || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION RUSH ITEM", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR RUSH ITEM", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};


export const getWAMessageDesignQC = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ITEM_DESIGN_CLIENT_WAQC || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/item");
  const header = {
    "Content-Type": "application/json",
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION GET WA MESSAGE", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET WA MESSAGE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};