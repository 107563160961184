import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetCustomerDetail, updateCustomerDetail } from "src/api/customer";
import CustomButton from "src/components/main/CustomButton";
import CustomCard from "src/components/main/CustomCard";
import CustomerRow from "src/components/main/CustomerForms/row";
import ModalConfirm from "src/components/main/ModalConfirm";

interface Props {
  isEdit: boolean;
  setIsEdit: (a: boolean) => void;
}
export default function Detail({ isEdit, setIsEdit }: Props) {
  const id = useLocation().pathname.split("/").pop();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState<Customer>({} as Customer);
  const [formData, setFormData] = useState<Customer>({} as Customer);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

  const fetchCustomerDetail = async () => {
    const response = await GetCustomerDetail(id!);
    setFormData(response?.data);
    setInitialData(response?.data);
  };

  useEffect(() => {
    fetchCustomerDetail();
  }, []);

  const handleCustomerInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "phone_number") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }

    setFormData((prevData: Customer) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async () => {
    const req: Customer = {
      ...formData,
    };

    const response = await updateCustomerDetail(req);

    if (response?.status === 200) {
      navigate("/customer");
    }
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModalConfirm(true);
  };

  const handleDiscard = () => {
    setIsEdit(false);
    setFormData(initialData);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  return (
    <Box component="form" width={"100%"} onSubmit={handleSubmitForm}>
      <CustomCard text="Customer Details">
        <CustomerRow
          disabled={!isEdit}
          value={formData}
          onChange={handleCustomerInputChange}
        />
      </CustomCard>
      <Box
        mt={4}
        display={"flex"}
        width={"100%"}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "flex-end",
          alignItems: {
            xs: "flex-start",
            md: "flex-end",
          },
          gap: {
            xs: 1,
            md: 2,
            lg: 3,
          },
        }}>
        {isEdit ? (
          <>
            <CustomButton
              variant="outlined"
              customType="cancel"
              onClick={handleDiscard}>
              Discard Changes
            </CustomButton>
            <CustomButton type="submit" variant="contained">
              Save Changes
            </CustomButton>
          </>
        ) : (
          <CustomButton variant="contained" onClick={handleEdit}>
            Edit
          </CustomButton>
        )}
      </Box>
      <ModalConfirm
        text="Are you sure to do this action?"
        open={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
