import {
  Box,
  Stack,
  Grid,
  InputAdornment,
  Link,
  FormControlLabel,
  Chip,
  Radio,
  RadioGroup,
} from "@mui/material";
import Offer from "./Offer";
import { TypeService, TypeDesign } from "src/components/main/Item/Type";
import { useEffect, useState } from "react";
import CustomText from "src/components/main/CustomText";
import CustomInput from "src/components/main/CustomInput";
import CustomButton from "src/components/main/CustomButton";
import { getCurrencySymbol, serviceType } from "src/utils/paramData";
import { useLocation, useNavigate } from "react-router-dom";
import { GetItemDetail, getRushData, updateItemDetail } from "src/api/item";
import CustomFormGroup from "src/components/main/CustomFormGroup";
import useThemeStore from "src/store/themeStore";
import NewInputNominal from "src/components/main/NewInputNominal";
import ModalRush from "./ModalRush";
import CustomCard from "src/components/main/CustomCard";

export default function Detail() {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [offer, setOffer] = useState<any>([]);
  const [rushData, setRushData] = useState<RushData>({} as RushData);
  const [rushModal, setRushModal] = useState<boolean>(false);

  const { theme } = useThemeStore();

  const navigate = useNavigate();
  const id = useLocation().pathname.split("/").pop();

  const fetchItemDetail = async () => {
    const response = await GetItemDetail(id!);
    if (!response) {
      navigate("/item");
    }
    setInitialData(response?.data?.item);
    setFormData(response?.data?.item);
    setOffer(response?.data?.offer);

    await fetchRushData(response?.data?.item?.id);
  };

  const fetchRushData = async (id: string) => {
    const response = await getRushData(id);
    if (!response) {
      navigate("/item");
    }
    setRushData(response);
  };

  useEffect(() => {
    fetchItemDetail();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await updateItemDetail(formData);
    await fetchItemDetail();
    setIsEdit(false);
  };

  const handleDiscard = () => {
    setIsEdit(false);
    setFormData(initialData);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleClickRush = () => {
    setRushModal(true);
  };

  return (
    <Box>
      <Box width={"100%"} component={"form"} onSubmit={handleSubmit}>
        <Stack>
          <Stack direction={"row"} gap={2}>
            <CustomText variant="h6" fontWeight={"bold"}>
              Item ID &emsp; :
            </CustomText>
            <CustomText variant="h6" fontWeight={"bold"}>
              {formData?.id}
            </CustomText>
          </Stack>
          <Stack direction={"row"} gap={2}>
            <CustomText variant="h6" fontWeight={"bold"}>
              Order ID &ensp; :
            </CustomText>
            <Link
              href={`/order/${formData?.order_id}`}
              sx={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                  textDecorationColor: theme?.inputLabel,
                },
              }}>
              <CustomText variant="h6" fontWeight={"bold"}>
                {formData?.order_id}
              </CustomText>
            </Link>
          </Stack>
        </Stack>
        <CustomCard text="Item Detail">
          <Grid
            container
            spacing={2}
            direction={"row"}
            justifyContent="space-evenly"
            alignItems="start">
            <Grid item sm={12} md={6} lg={4}>
              <Stack my={"10px"}>
                <CustomText variant="body1" required={isEdit}>
                  Name
                </CustomText>
                <CustomInput
                  disabled={!isEdit}
                  onChange={handleInputChange}
                  size="small"
                  id="nama"
                  name="name"
                  value={formData?.name}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">Design Type</CustomText>
                <TypeDesign
                  id="design_type"
                  name="design_type"
                  value={formData?.design_type}
                  disabled
                  onChange={handleInputChange}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">Item Price</CustomText>
                <NewInputNominal
                  disabled
                  onChange={handleInputChange}
                  size="small"
                  id="cost"
                  name="cost"
                  value={formData?.cost?.toString() || "0"}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCurrencySymbol(formData?.payment?.currency) || "Rp"}
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">PIC User</CustomText>
                <CustomInput
                  disabled
                  onChange={handleInputChange}
                  size="small"
                  id="pic_user"
                  name="pic_user"
                  value={formData?.pic_user_name}
                />
              </Stack>
              {formData?.is_leather && (
                <Stack my={"10px"}>
                  <CustomText variant="body1">Leather Name</CustomText>
                  <CustomInput
                    disabled={!isEdit}
                    onChange={handleInputChange}
                    size="small"
                    id="leather_type"
                    name="leather_type"
                    value={formData?.leather_type}
                  />
                </Stack>
              )}
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <Stack my={"10px"}>
                <CustomText variant="body1" required={isEdit}>
                  Brand
                </CustomText>
                <CustomInput
                  disabled={!isEdit}
                  onChange={handleInputChange}
                  size="small"
                  id="brand"
                  name="brand"
                  value={formData?.brand}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1" required={isEdit}>
                  Design Name
                </CustomText>
                <CustomInput
                  disabled={!isEdit}
                  onChange={handleInputChange}
                  size="small"
                  id="design_name"
                  name="design_name"
                  value={formData?.design_name}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1" required={isEdit}>
                  Color / Type
                </CustomText>
                <CustomInput
                  disabled={!isEdit}
                  onChange={handleInputChange}
                  size="small"
                  id="color"
                  name="color"
                  value={formData?.color}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">
                  Base From {formData?.from_client}
                </CustomText>
                <CustomFormGroup>
                  <RadioGroup
                    row
                    name="from_client"
                    value={formData?.from_client ? "client" : "supplier"}
                    onChange={handleInputChange}>
                    <FormControlLabel
                      value="supplier"
                      control={<Radio />}
                      label="Supplier"
                      disabled
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: theme?.inputLabel,
                          "&.Mui-disabled": {
                            color: theme?.inputLabel,
                          },
                        },
                      }}
                    />
                    <FormControlLabel
                      value="client"
                      control={<Radio />}
                      label="Client"
                      disabled
                    />
                  </RadioGroup>
                </CustomFormGroup>
              </Stack>
              {(formData?.service_type === serviceType[1].value ||
                formData?.service_type === serviceType[2].value) && (
                <>
                  <CustomFormGroup>
                    <Stack my={"10px"}>
                      <CustomText variant="body1">Leather From</CustomText>
                      <RadioGroup
                        row
                        name="leather_from_client"
                        defaultValue={
                          formData?.leather_from_client ? "client" : "supplier"
                        }
                        onChange={handleInputChange}>
                        <FormControlLabel
                          value="supplier"
                          control={<Radio />}
                          label="Supplier"
                          disabled
                        />
                        <FormControlLabel
                          value="client"
                          control={<Radio />}
                          label="Client"
                          disabled
                        />
                      </RadioGroup>
                    </Stack>
                  </CustomFormGroup>
                </>
              )}
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <Stack my={"10px"}>
                <CustomText variant="body1">Service Type</CustomText>
                <TypeService
                  id="service_type"
                  name="service_type"
                  disabled
                  onChange={handleInputChange}
                  value={formData?.service_type}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1" required={isEdit}>
                  Size
                </CustomText>
                <CustomInput
                  disabled={!isEdit}
                  onChange={handleInputChange}
                  size="small"
                  id="size"
                  name="size"
                  value={formData?.size}
                />
              </Stack>
            </Grid>
          </Grid>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"flex-start"}
            gap={2}>
            <Stack my={"10px"}>
              <Chip
                color="primary"
                sx={{ width: "fit-content", px: 2 }}
                label={formData?.status}
              />
            </Stack>
            {formData?.is_rush && (
              <Stack my={"10px"}>
                <Chip
                  color="primary"
                  sx={{ width: "fit-content", px: 2 }}
                  label={`Rush Item (${formData?.rush_order_id})`}
                />
              </Stack>
            )}
          </Box>
          <Stack my={"10px"}>
            <CustomText variant="body1">Additional Notes</CustomText>
            <CustomInput
              disabled={!isEdit}
              onChange={handleInputChange}
              multiline
              rows={4}
              size="small"
              id="notes"
              name="notes"
              value={formData?.notes}
            />
          </Stack>
        </CustomCard>
        {offer?.length > 0 && formData?.supplier_id === "" && (
          <CustomCard text="Supplier Offering">
            <Box width={"70%"}>
              {formData.supplier_id ? (
                <Offer
                  data={offer.find(
                    (item: any) =>
                      item.supplier_id === formData?.supplier_id &&
                      item.status === "Approved"
                  )}
                  starting_price={formData?.starting_price}
                  refetch={fetchItemDetail}
                />
              ) : (
                offer.map((item: any) => (
                  <Offer
                    key={item.id}
                    data={item}
                    starting_price={formData?.starting_price}
                    refetch={fetchItemDetail}
                  />
                ))
              )}
            </Box>
          </CustomCard>
        )}
        {formData?.supplier_id !== "" && (
          <CustomCard text="Supplier Data">
            <Box mt={0}>
              <Grid container columnSpacing={2}>
                <Grid item sm={12} md={6} lg={6}>
                  <Stack my={"10px"}>
                    <CustomText variant="body1">Supplier Name</CustomText>
                    <CustomInput
                      disabled={!isEdit}
                      onChange={handleInputChange}
                      size="small"
                      id="leather_type"
                      name="leather_type"
                      value={formData?.leather_type}
                    />
                  </Stack>
                  <Stack my={"10px"}>
                    <CustomText variant="body1">Offering</CustomText>
                    <CustomInput
                      disabled={!isEdit}
                      onChange={handleInputChange}
                      size="small"
                      id="leather_type"
                      name="leather_type"
                      value={formData?.leather_type}
                    />
                  </Stack>
                  <Stack my={"10px"}>
                    <Chip
                      color="primary"
                      sx={{ width: "fit-content", px: 2 }}
                      label={formData?.status}
                    />
                  </Stack>
                </Grid>

                <Grid item sm={12} md={6} lg={6}>
                  <Stack my={"10px"}>
                    <CustomText variant="body1">Resi No</CustomText>
                    <CustomInput
                      disabled={!isEdit}
                      onChange={handleInputChange}
                      size="small"
                      id="leather_type"
                      name="leather_type"
                      value={formData?.leather_type}
                    />
                  </Stack>
                  <Stack my={"10px"}>
                    <CustomText variant="body1">Expedition</CustomText>
                    <CustomInput
                      disabled={!isEdit}
                      onChange={handleInputChange}
                      size="small"
                      id="leather_type"
                      name="leather_type"
                      value={formData?.leather_type}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </CustomCard>
        )}
        {formData?.status?.toLowerCase() !== "canceled" && (
          <Box
            mt={4}
            display={"flex"}
            width={"100%"}
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "flex-end",
              alignItems: {
                xs: "flex-start",
                md: "flex-end",
              },
              gap: {
                xs: 1,
                md: 2,
                lg: 3,
              },
            }}>
            {offer &&
              initialData?.from_client === false &&
              offer?.length === 0 && (
                <>
                  {isEdit ? (
                    <>
                      <CustomButton
                        variant="outlined"
                        customType="cancel"
                        onClick={handleDiscard}>
                        Discard Changes
                      </CustomButton>
                      <CustomButton type="submit" variant="contained">
                        Save Changes
                      </CustomButton>
                    </>
                  ) : (
                    <CustomButton variant="contained" onClick={handleEdit}>
                      Edit
                    </CustomButton>
                  )}
                </>
              )}
            {initialData?.from_client &&
              initialData?.resi_item_send?.length === 0 && (
                <>
                  {isEdit ? (
                    <>
                      <CustomButton
                        variant="outlined"
                        customType="cancel"
                        onClick={handleDiscard}>
                        Discard Changes
                      </CustomButton>
                      <CustomButton type="submit" variant="contained">
                        Save Changes
                      </CustomButton>
                    </>
                  ) : (
                    <CustomButton variant="contained" onClick={handleEdit}>
                      Edit
                    </CustomButton>
                  )}
                </>
              )}
            {!formData?.is_rush &&
              formData?.status?.toLowerCase() !== "completed" && (
                <CustomButton variant="contained" onClick={handleClickRush}>
                  Rush
                </CustomButton>
              )}
          </Box>
        )}
      </Box>

      <ModalRush
        isOpen={rushModal}
        onClose={() => setRushModal(false)}
        data={rushData}
        refetch={fetchItemDetail}
      />
    </Box>
  );
}
