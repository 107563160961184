import axios from "axios";
import { useLoadingStore } from "src/store/loadingStore";

const { showNotification } = useLoadingStore.getState();

const Request = (headers: any) => {
  return axios.create({
    timeout: 30000,
    headers: headers,
  });
};

export const sendRequestGET = async (baseURL: string, header?: any) => {
  const headers = {
    "Content-Type": "application/json",
    ...header,
  };

  console.info("REQ", baseURL, headers);

  try {
    const response = await Request(headers).get(baseURL);

    console.info("RES", response);

    return response;
  } catch (error: any) {
    console.error("REQUEST ERROR", error);
    showNotification(
      error?.response?.data?.message || error?.response?.data || error?.message,
      "error"
    );
    if (error?.response?.status === 401) {
      showNotification("Session Expired. Please Login Again", "error");
      localStorage.clear();
      window.location.href = "/login";
    }
    throw new Error(
      error?.response?.data?.message || error?.response?.data || error?.message
    );
  }
};

export const sendRequestPOST = async (
  baseURL: string,
  body?: any,
  header?: any
) => {
  const headers = {
    ...header,
  };

  if (!header || !header["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }

  console.info("REQ", baseURL, headers, body);

  try {
    const response = await Request(headers).post(baseURL, body);

    console.info("RES", response);

    return response;
  } catch (error: any) {
    console.error("REQUEST ERROR", error);
    // showNotification(error?.response?.data?.message || error?.response?.data || error?.message, "error");
    if (error?.response?.status === 401) {
      showNotification("Session Expired. Please Login Again", "error");
      localStorage.clear();
      window.location.href = "/login";
    }
    throw new Error(
      error?.response?.data?.message || error?.response?.data || error?.message
    );
  }
};

export const sendRequestPUT = async (
  baseURL: string,
  body?: any,
  header?: any
) => {
  const headers = {
    ...header,
  };

  if (!header || !header["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }

  console.info("REQ", baseURL, headers, body);

  try {
    const response = await Request(headers).put(baseURL, body);

    console.info("RES", response);

    return response;
  } catch (error: any) {
    console.error("REQUEST ERROR", error);
    showNotification(
      error?.response?.data?.message || error?.response?.data || error?.message,
      "error"
    );
    if (error?.response?.status === 401) {
      showNotification("Session Expired. Please Login Again", "error");
      localStorage.clear();
      window.location.href = "/login";
    }
    throw new Error(
      error?.response?.data?.message || error?.response?.data || error?.message
    );
  }
};

export const sendRequestDELETE = async (baseURL: string, header?: any) => {
  const headers = {
    "Content-Type": "application/json",
    ...header,
  };

  console.info("REQ", baseURL, headers);

  try {
    const response = await Request(headers).delete(baseURL);

    console.info("RES", response);

    return response;
  } catch (error: any) {
    console.error("REQUEST ERROR", error);
    showNotification(
      error?.response?.data?.message || error?.response?.data || error?.message,
      "error"
    );
    if (error?.response?.status === 401) {
      showNotification("Session Expired. Please Login Again", "error");
      localStorage.clear();
      window.location.href = "/login";
    }
    throw new Error(
      error?.response?.data?.message || error?.response?.data || error?.message
    );
  }
};
