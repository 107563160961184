import { Box, Card, Grid, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import Filter from "./components/Filter";
import { downloadReportCustomer, exportExcelCustomer, SearchCustomer, SearchCustomerPageList } from "src/api/customer";
import SearchIcon from "@mui/icons-material/Search";

import { useFilterStore } from "src/store/filterStore";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CustomButton from "src/components/main/CustomButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import useThemeStore from "src/store/themeStore";
import { CUSTOMER_COLUMNS } from "src/constants/customer";
import CustomInput from "src/components/main/CustomInput";
import { useDebouncedCallback } from "use-debounce";
import { DecimalFormatter } from "src/utils/formatter";
import { downloadBase64File } from "src/utils/decoder";

function CustomerList() {
  const { theme } = useThemeStore();
  const [data, setData] = useState<Customer[]>([]);
  const { resetFilterStore } = useFilterStore();
  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const navigate = useNavigate();
  const onClickDetail = (id: string) => {
    navigate(`/customer/${id}`);
  };

  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const fetchSearchCustomer = async (key: string,start_date:string,end_date:string) => {
    if (key === null || key === undefined) {
      return;
    }

    const req = {
      key: key.length > 3 ? key : "", // Need at least 4 characters to search
      page_number: filter.page,
      number_of_record: filter.limit,
      start_date:start_date,
      end_date:end_date,
    };

    try {
      const response = await SearchCustomerPageList(req);
      setData(formatData(response?.data?.Data));
      handleFilterChange("total_page", response?.data?.total_page);
    } catch (error: any) {
      console.error("Error fetching customer search:", error);
    }
  };

  useEffect(() => {
    fetchSearchCustomer(filter.key,filter.start_date,filter.end_date);
  }, [filter.key, filter.page, filter.limit,filter.start_date,filter.end_date]);

  useEffect(() => {
    return () => {
      resetFilterStore();
    };
  }, []);

  const formatData = (data: Customer[]) => {
    return data?.map((item: Customer) => {
      return {
        ...item,
        total_spent: DecimalFormatter(item?.total_spent?.toString()),
        last_order_date: item?.last_order_date?.split("T")[0],
      };
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterChange("key", e.target.value);
  };
  const debouncedFetch = useDebouncedCallback(handleSearch, 1000);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFetch(e);
  };

  const handleExportExcel = async () => {
    await downloadReportCustomer();

  };

  return (
    <Box>
      <PageTitle>Customer</PageTitle>
      <Grid container columnSpacing={2}>
        <Grid item sm={2}>
        <Filter filter={filter} onChangeFilter={handleFilterChange}/>
        </Grid>
        <Grid item sm={10}>
          <Card>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}
            >
              <Box sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Customer List
                </CustomText>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
                <CustomInput
                  placeholder="Search"
                  size="small"
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: theme?.icon }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomButton variant="contained" startIcon={<IosShareIcon />} onClick={handleExportExcel}>
                  Export to Excel
                </CustomButton>
              </Box>
            </Box>
            <CustomTable
              columns={CUSTOMER_COLUMNS}
              data={data || []}
              onClickDetail={onClickDetail}
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CustomerList;
