import {
  Box,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/main/CustomButton";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import useThemeStore from "src/store/themeStore";
import { getCurrentTime } from "src/utils/formatter";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  DoUploadDesign,
  getItemLogAttachment,
  UpdateItemLog,
} from "src/api/item";
import { HideLoading, ShowLoading } from "../Loading";
import NotificationAlert from "../Alert";
import { DropBox } from "../Dropbox";
import ImageHolder from "../CustomDropbox/ImageHolder";
import ModalConfirm from "../ModalConfirm";
import CustomDropbox from "../CustomDropbox";

export default function UploadDesign({ data, refetch }: any) {
  const [files, setFiles] = useState<any>([]);
  const [designItem, setDesignItem] = useState<any>([]);
  const [itemAttachment, setItemAttachment] = useState<string[]>([]);
  const [openModalUpload, setOpenModalUpload] = useState<boolean>(false);
  const [openModalComplete, setOpenModalComplete] = useState<boolean>(false);

  const id = useLocation().pathname.split("/").pop();
  const { theme } = useThemeStore();

  const handleFileUpload = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleDelete = (fileName: string) => {
    setFiles((prevFiles: any[]) =>
      prevFiles.filter((file: { name: string }) => file.name !== fileName)
    );
  };

  useEffect(() => {
    const itemWithAttachment = data
      ?.filter((item: any) => item.attachment)
      .map((item: any) => item.attachment);
    setItemAttachment(itemWithAttachment);
    if (itemWithAttachment) {
      itemWithAttachment?.map((item: string) => fetchItemAttachment(item));
    }
  }, []);

  const fetchItemAttachment = async (id: string) => {
    const response = await getItemLogAttachment(id);
    response?.data === null
      ? setDesignItem([...designItem])
      : setDesignItem((prev: any) => [...prev, ...response.data.flat()]);
  };

  const handleSubmit = async () => {
    const data = {
      item_id: id,
      status: "COMPLETED",
      status_desc: "Design Uploaded",
      notes: "Design Uploaded",
      enum_status: "COMPLETED",
    };

    try {
      const response = await UpdateItemLog(data);
      NotificationAlert({
        message: response?.data?.message,
        status: "success",
      });
    } catch (error: any) {
      console.error("Error upload design:", error);
      NotificationAlert({ message: error.message, status: "error" });
    } finally {
      refetch();
    }
  };

  const handleUpload = async () => {
    const data = {
      item_id: id!,
      files: files,
    };
    try {
      const response = await DoUploadDesign(data);
      NotificationAlert({
        message: response?.data?.message,
        status: "success",
      });
    } catch (error: any) {
      console.error("Error upload design:", error);
      NotificationAlert({ message: error.message, status: "error" });
    } finally {
      files?.forEach((file: any) => {
        URL.revokeObjectURL(file.content);
        handleDelete(file.name);
      });
      await refetch();
    }
  };

  return (
    <Box component="form" sx={{ minWidth: "50%", width: "fit-content" }}>
      <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <CustomText variant="body1" fontWeight={"bold"}>
            Upload Design by Admin
          </CustomText>
          <CustomText variant="body2">{getCurrentTime()}</CustomText>
        </Stack>

        <Box
          mt={4}
          display={"flex"}
          justifyContent={"flex-start"}
          flexDirection={"row"}
        >
          <CustomDropbox
            size={150}
            files={files}
            existingFiles={designItem}
            onUpload={handleFileUpload}
            onDelete={handleDelete}
          />
        </Box>
        <Box
          mt={4}
          display={"flex"}
          justifyContent={"flex-end"}
          sx={{ gap: 2 }}
        >
          <CustomButton
            variant="contained"
            onClick={() => setOpenModalUpload(true)}
            disabled={files?.length === 0}
          >
            Upload
          </CustomButton>
          <CustomButton
            disabled={designItem?.length === 0}
            variant="contained"
            onClick={() => setOpenModalComplete(true)}
          >
            Complete
          </CustomButton>
        </Box>
      </CustomPaper>
      <ModalConfirm
        text="Are you sure you want to upload?"
        open={openModalUpload}
        onClose={() => setOpenModalUpload(false)}
        onSubmit={handleUpload}
      />
      <ModalConfirm
        text="Are you sure you want to complete this step?"
        open={openModalComplete}
        onClose={() => setOpenModalComplete(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
