import { Box, Card, IconButton, InputAdornment } from "@mui/material";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { useNavigate } from "react-router-dom";
import PageTitle from "src/components/main/CustomText/PageTitle";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "src/components/main/CustomButton";
import useThemeStore from "src/store/themeStore";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { deleteCollectiveLeave, getCollectiveLeave } from "src/api/company";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { COLLECTIVE_LEAVE_COLUMNS } from "src/constants/company";
import ModalConfirm from "src/components/main/ModalConfirm";
import CustomInput from "src/components/main/CustomInput";
import { useDebouncedCallback } from "use-debounce";

export default function CollectiveLeave() {
  const { theme } = useThemeStore();
  const navigate = useNavigate();

  const [data, setData] = useState<CollectiveLeave[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const handleAdd = () => {
    navigate("/company/collective-leave/add");
  };

  const fetchData = async () => {
    const res = await getCollectiveLeave();
    setData(processedData(res?.data));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onDelete = async () => {
    await deleteCollectiveLeave(selectedData);
    onCloseModal();
    fetchData();
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setSelectedData("");
  };

  const handleDelete = (id: string) => {
    setOpenModal(true);
    setSelectedData(id);
  };

  const processedData = (data: CollectiveLeave[]) => {
    return data?.map((item) => {
      return {
        ...item,
        start_date: item?.start_date?.split("T")[0],
        end_date: item?.end_date?.split("T")[0],
        actions: (
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "rgba(255,255,255, 0.2)",
              },
            }}
            onClick={() => handleDelete(item?.id)}
          >
            <DeleteOutlineOutlinedIcon color="error" />
          </IconButton>
        ),
      };
    });
  };

  const filterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleFilterChange("key", value);
    const filtered = data?.filter((item: any) => {
      return item?.title_leave?.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(filtered);
  };

  const debouncedFilter = useDebouncedCallback(filterSearch, 1000);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e);
  };

  return (
    <Box>
      <PageTitle>Collective Leave</PageTitle>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          borderBottom={`1px solid ${theme?.border}`}
          alignContent={"center"}
          p={2}
        >
          <Box sx={{ display: "inline-block", alignContent: "center" }}>
            <CustomText variant="subtitle1" fontWeight={700} align="center">
              Collective Leave List
            </CustomText>
          </Box>
          <Box
            display={"flex"}
            gap={2}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            <CustomInput
              placeholder="Search"
              size="small"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme?.icon }} />
                  </InputAdornment>
                ),
              }}
            />
            <CustomButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAdd}
            >
              Add Leave
            </CustomButton>
          </Box>
        </Box>
        <CustomTable
          columns={COLLECTIVE_LEAVE_COLUMNS}
          data={filter.key ? filteredData : data}
          pagination={false}
          filter={filter}
          onChangeFilter={handleFilterChange}
        />
      </Card>
      <ModalConfirm
        text="Are you sure you want to delete this leave?"
        open={openModal}
        onSubmit={onDelete}
        onClose={onCloseModal}
      />
    </Box>
  );
}
