import InstagramppIcon from "@mui/icons-material/Instagram";
import EmailppIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  Grid,
  ImageListItem,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cancelOrder, GetOrderDetail } from "src/api/order";
import NotificationAlert from "src/components/main/Alert";
import CustomButton from "src/components/main/CustomButton";
import CustomCard from "src/components/main/CustomCard";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import InputDate from "src/components/main/Datepicker";
import ModalConfirm from "src/components/main/ModalConfirm";
import NewInputNominal from "src/components/main/NewInputNominal";
import { useOrderStore } from "src/store/orderStore";
import useThemeStore from "src/store/themeStore";
import {
  currencies,
  getCurrencySymbol,
  orderFrom,
  paymentMethod,
} from "../../../../utils/paramData";

export default function Detail() {
  const navigate = useNavigate();
  const { theme } = useThemeStore();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const { setCustomer, setItemList, setIsOrderComplete, setOrder } =
    useOrderStore();
  const id = useLocation().pathname.split("/").pop();

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        const response = await GetOrderDetail(id!);
        setInitialData(response?.data);
        setFormData(response?.data);
        setCustomer(response?.data?.customer_data);
        setItemList(response?.data?.list_item);
        setIsOrderComplete(response?.data?.is_order_completed);
        setOrder(response?.data?.order_data);

        if (response?.is_error) {
          navigate("/order");
        }
      } catch (error: any) {
        console.error("Error fetching order detail:", error);
        NotificationAlert({ message: error.message, status: "error" });
      }
    };

    fetchOrderDetail();
  }, []);

  const handleCustomerInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    setFormData((prevData: any) => ({
      ...prevData,
      customer_data: {
        ...prevData.customer_data,
        [name]: value,
      },
    }));
  };

  const handleOrderInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "amount") {
      newValue = value.replace(/[^0-9\\.]/g, "");
    }

    setFormData((prevData: any) => ({
      ...prevData,
      order_data: {
        ...prevData.order_data,
        [name]: newValue,
      },
    }));
  };

  const getLabelForContact = (orderFromValue: any) => {
    switch (orderFromValue) {
      case "Instagram":
        return "Instagram Username";
      case "Email":
        return "Email Address";
      case "Whatsapp":
        return "Whatsapp Number";
      default:
        return "Contact Information";
    }
  };

  const handlePaymentInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "amount") {
      newValue = value.replace(/[^0-9\\.]/g, "");
    }

    setFormData((prevData: any) => ({
      ...prevData,
      payment: {
        ...prevData.payment,
        [name]: newValue,
      },
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleDiscard = () => {
    setIsEdit(false);
    setFormData(initialData);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleCancel = async () => {
    const req: CancelOrder = {
      order_id: formData?.order_data?.id,
      notes: formData?.order_data?.notes,
    };

    await cancelOrder(req);
    setCancelModal(false);
    navigate("/order");
  };
  return (
    <Box component={"form"} onSubmit={handleSubmit}>
      <CustomText variant="h6" fontWeight={"bold"}>
        {`Order ID : ${formData?.order_data?.id}`}
      </CustomText>
      <CustomCard text="Customer Data">
        <Grid container columnSpacing={4}>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Name</CustomText>
              <CustomInput
                onChange={handleCustomerInputChange}
                disabled
                size="small"
                id="name"
                name="name"
                value={formData?.customer_data?.name || ""}
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Birthdate</CustomText>
              <InputDate
                onChange={handleCustomerInputChange}
                disabled
                id="birth_date"
                name="birth_date"
                value={formData?.customer_data?.birth_date || ""}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Phone / Whatsapp</CustomText>
              <CustomInput
                onChange={handleCustomerInputChange}
                disabled
                size="small"
                id="phone_number"
                name="phone_number"
                value={formData?.customer_data?.phone_number || ""}
                InputProps={{
                  endAdornment: (
                    <CustomText variant="body1">
                      <Link
                        href={`https://wa.me/${
                          formData?.customer_data?.phone_number?.startsWith(
                            "62"
                          )
                            ? formData?.customer_data?.phone_number
                            : `62${formData?.customer_data?.phone_number?.replace(
                                /^0+/,
                                ""
                              )}`
                        }`}
                        target="_blank">
                        <WhatsAppIcon />
                      </Link>
                    </CustomText>
                  ),
                }}
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Tiktok</CustomText>
              <CustomInput
                onChange={handleCustomerInputChange}
                disabled
                size="small"
                id="tiktok"
                name="tiktok"
                value={formData?.customer_data?.tiktok || ""}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Email</CustomText>
              <CustomInput
                onChange={handleCustomerInputChange}
                disabled
                size="small"
                id="email"
                name="email"
                value={formData?.customer_data?.email || ""}
                InputProps={{
                  endAdornment: (
                    <CustomText variant="body1">
                      {formData?.customer_data?.email && (
                        <Link
                          href={`mailto:${
                            formData?.customer_data?.email || ""
                          }`}
                          target="_blank">
                          <EmailppIcon />
                        </Link>
                      )}
                    </CustomText>
                  ),
                }}
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Instagram</CustomText>
              <CustomInput
                onChange={handleCustomerInputChange}
                disabled
                size="small"
                id="instagram"
                name="instagram"
                value={formData?.customer_data?.instagram || ""}
                InputProps={{
                  endAdornment: (
                    <CustomText variant="body1">
                      {formData?.customer_data?.instagram && (
                        <Link
                          href={`https://www.instagram.com/${
                            formData?.customer_data?.instagram || ""
                          }`}
                          target="_blank">
                          <InstagramppIcon />
                        </Link>
                      )}
                    </CustomText>
                  ),
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </CustomCard>
      <CustomCard text="Order Data">
        <Grid container columnSpacing={4}>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Order Deadline</CustomText>
              <InputDate
                onChange={handleOrderInputChange}
                disabled
                id="orderDeadline"
                name="orderDeadline"
                value={formData?.order_data?.deadline || ""}
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Order From</CustomText>
              <CustomInput
                onChange={handleOrderInputChange}
                disabled={!isEdit}
                size="small"
                id="orderFrom"
                name="orderFrom"
                select
                value={formData?.order_data?.orderFrom || orderFrom[0].value}>
                {orderFrom.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Currency</CustomText>
              <CustomInput
                onChange={handleOrderInputChange}
                disabled
                size="small"
                id="currency"
                name="currency"
                select
                value={formData?.order_data?.currency || currencies[0].value}>
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">
                {getLabelForContact(formData?.order_data?.order_from)}
              </CustomText>
              <CustomInput
                size="small"
                id="contact"
                name="contact"
                disabled={!isEdit}
                value={formData?.order_data?.order_contact}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Amounts Order</CustomText>
              <NewInputNominal
                onChange={handleOrderInputChange}
                disabled
                size="small"
                id="amount"
                name="amount"
                value={
                  formData?.order_data?.amount
                    ? formData?.order_data?.amount.toString()
                    : ""
                }
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(formData?.order_data?.currency)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Referal Code</CustomText>
              <CustomInput
                onChange={handleOrderInputChange}
                disabled={!isEdit}
                size="small"
                id="referal_code"
                name="referal_code"
                value={formData?.order_data?.referal_code || ""}
              />
            </Stack>
          </Grid>
        </Grid>
      </CustomCard>
      <CustomCard text="Payment Data">
        <Grid container columnSpacing={4}>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Currency</CustomText>
              <CustomInput
                onChange={handlePaymentInputChange}
                disabled
                size="small"
                id="payment_currency"
                name="payment_currency"
                select
                value={
                  formData?.order_data?.payment_currency || currencies[0].value
                }>
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Amount</CustomText>
              <NewInputNominal
                onChange={handlePaymentInputChange}
                disabled
                size="small"
                id="payment_nominal"
                name="payment_nominal"
                value={formData?.order_data?.payment_nominal.toString() || ""}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formData?.order_data?.payment_currency
                        ? getCurrencySymbol(
                            formData?.order_data?.payment_currency
                          )
                        : "Rp"}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Payment Method</CustomText>
              <CustomInput
                onChange={handlePaymentInputChange}
                disabled
                size="small"
                id="payment_method"
                name="payment_method"
                select
                value={
                  formData?.order_data?.payment_method || paymentMethod[0].value
                }>
                {paymentMethod.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
          </Grid>
        </Grid>
        <Box
          mt={4}
          display={"flex"}
          justifyContent={"flex-start"}
          gap={2}
          flexDirection={"row"}
          height={"20%"}>
          {formData?.bukti_tf?.length > 0 &&
            formData?.bukti_tf?.map((item: any, index: number) => (
              <ImageListItem key={index} sx={{ marginRight: 2, width: 300 }}>
                <img
                  src={
                    item?.url_foto ||
                    "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"
                  }
                  loading="lazy"
                />
              </ImageListItem>
            ))}
        </Box>
      </CustomCard>
      <CustomCard text="Shipment Data">
        <Paper
          style={{ padding: 20, marginBottom: 20, width: "100%" }}
          sx={{
            background: theme?.inputPrimary,
          }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}>
            <Stack direction={"column"}>
              <Stack direction={"row"}>
                <CustomText variant="body1" gutterBottom fontWeight={"bold"}>
                  {"Rumah"}
                </CustomText>
                <CustomText variant="body1" gutterBottom fontWeight={"bold"}>
                  &ensp;{"-"}&ensp;
                </CustomText>{" "}
                <CustomText variant="body1" gutterBottom>
                  {`${formData?.shipment_data?.recipient_name} (${formData?.shipment_data?.recipient_contact})`}
                </CustomText>
              </Stack>
              <CustomText variant="body1" gutterBottom>
                {`${formData?.shipment_data?.address}, ${formData?.shipment_data?.city}, ${formData?.shipment_data?.province}, ${formData?.shipment_data?.country}, ${formData?.shipment_data?.postal_code}`}
              </CustomText>
            </Stack>
            {/* <Box>
              <CustomButton
                variant="contained"
                disabled={!isEdit}
                onClick={() => navigate(`/order/${id}/shipping`)}
                startIcon={<NavigateNextIcon />}
              />
            </Box> */}
          </Stack>
        </Paper>
      </CustomCard>
      {formData?.order_data?.status_order.toLowerCase() === "canceled" ||
      formData?.order_data?.status_order.toLowerCase() === "done" ? (
        <CustomCard text="Order Cancellation">
          <CustomText variant="body1">Notes</CustomText>
          <Box display="flex" flexDirection="row" alignItems="center" gap={4}>
            <Stack width="100%">
              <CustomInput
                onChange={handleOrderInputChange}
                disabled={
                  formData?.order_data?.status_order.toLowerCase() ===
                    "canceled" ||
                  formData?.order_data?.status_order.toLowerCase() === "done"
                }
                fullWidth
                size="small"
                id="notes"
                name="notes"
                value={formData?.order_data?.notes || ""}
              />
            </Stack>
          </Box>
        </CustomCard>
      ) : (
        <CustomCard text="Order Cancellation">
          <CustomText variant="body1">Notes</CustomText>
          <Box display="flex" flexDirection="row" alignItems="center" gap={4}>
            <Stack width="80%">
              <CustomInput
                onChange={handleOrderInputChange}
                fullWidth
                size="small"
                id="notes"
                name="notes"
                value={formData?.order_data?.notes || ""}
              />
            </Stack>
            <Stack width="20%">
              <CustomButton
                variant="contained"
                disabled={
                  formData?.order_data?.status_order.toLowerCase() ===
                  "canceled"
                }
                onClick={() => setCancelModal(true)}>
                {formData?.order_data?.status_order.toLowerCase() === "canceled"
                  ? "Order Canceled"
                  : "Cancel Order"}
              </CustomButton>
            </Stack>
          </Box>
        </CustomCard>
      )}

      <Box
        mt={4}
        display={"flex"}
        width={"100%"}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "flex-end",
          alignItems: "center",
          gap: {
            xs: 1,
            md: 2,
            lg: 3,
          },
        }}>
        {/* {isEdit ? (
          <>
            <CustomButton
              variant="outlined"
              customType="cancel"
              onClick={handleDiscard}
            >
              Discard Changes
            </CustomButton>
            <CustomButton type="submit" variant="contained">
              Save Changes
            </CustomButton>
          </>
        ) : (
          <CustomButton variant="contained" onClick={handleEdit}>
            Edit
          </CustomButton>
        )} */}
      </Box>
      <ModalConfirm
        text="Are you sure you want to cancel this order?"
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        onSubmit={handleCancel}
      />
    </Box>
  );
}
