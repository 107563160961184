import {
  Box,
  Stack,
  MenuItem,
  Grid,
  CircularProgress,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  Divider,
  InputAdornment,
} from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import { priorityLevel, taskPoint } from "src/utils/paramData";
import React, { Fragment, useEffect, useState } from "react";
import NotificationAlert from "../Alert";
import { useDebouncedCallback } from "use-debounce";
import { SearchPainter } from "src/api/user";
import { checkWorkingDay, CreateTaskWorker, UpdateItemLog } from "src/api/item";
import InputDate from "../Datepicker";
import TaskPoint from "../TaskPoint";
import { useLocation } from "react-router-dom";
import useThemeStore from "src/store/themeStore";
import NewInputNominal from "../NewInputNominal";
import { getCurrentTime, parseAmount } from "src/utils/formatter";
import { useLoadingStore } from "src/store/loadingStore";
import ModalConfirm from "../ModalConfirm";

type TType = "r" | "d" | "p"; // Recon, Design, Paint
interface Props {
  type: TType;
  refetch: () => void;
}
export default function CreateTask({ type, refetch }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [data, setData] = useState<any>({
    man_days: 3,
    priority: priorityLevel[0].value,
    task_point: taskPoint[0].value,
  });
  const [workDay, setWorkDay] = useState<any>({});
  const [openModalComplete, setOpenModalComplete] = useState<boolean>(false);
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);

  const { showNotification } = useLoadingStore();
  const id = useLocation().pathname.split("/").pop();
  const { theme } = useThemeStore();

  const fetchSearchAssignee = async (key: string) => {
    if (key === "" || key === null || key === undefined) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await SearchPainter();
      response.data ? setAssigneeList(response.data) : setAssigneeList([]);
    } catch (error: any) {
      console.error("Error fetching assignee:", error);
      showNotification(error.message, "error");
    } finally {
      setIsLoading(false); // Reset loading state after API call
    }
  };

  const debouncedFetch = useDebouncedCallback(fetchSearchAssignee, 1000);

  const handleInputChange = (newInputValue: string) => {
    debouncedFetch(newInputValue);
  };

  const handleSelect = (event: any, newValue: any | null) => {
    setSelectedUser(newValue);
    setData((prevData: any) => ({ ...prevData, ["assignee"]: newValue?.id }));
  };

  const handleCheckWorkingDay = async (data: any) => {
    const response = await checkWorkingDay(data);
    setWorkDay(response?.data);
  };

  useEffect(() => {
    if (data?.priority && data?.assignee && data?.man_days) {
      const req = {
        user_id: selectedUser?.id,
        man_days: data?.man_days,
      };
      handleCheckWorkingDay(req);
    }
  }, [selectedUser?.id, data?.man_days]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    const req: CreateTask = {
      user_id: selectedUser?.id,
      item_id: id!,
      priority: data?.priority,
      type_task:
        type === "r" ? "RECONER" : type === "d" ? "DESIGN" : data?.task_point,
      main_day: Number(data?.man_days),
      cost: parseAmount(data?.cost),
    };

    await CreateTaskWorker(req);
    await refetch();
    setData({
      priority: priorityLevel[0].value,
      task_point: taskPoint[0].value,
    });
    setOpenModalCreate(false);
  };

  const handleComplete = async () => {
    const req = {
      item_id: id,
      status: "COMPLETED",
      status_desc: "Task Completed",
      notes: "Task Completed",
      enum_status: "COMPLETED",
    };

    await UpdateItemLog(req);
    await refetch();
    setOpenModalComplete(false);
  };

  const handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModalCreate(true);
  };

  const filterOptions = (options: any[], { inputValue }: any) => {
    return options.filter((option: { username: string }) =>
      option.username.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Box component={"form"} onSubmit={handleFormSubmit}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}>
        <CustomText variant="body1" fontWeight={"bold"}>
          Create Task{" "}
          {type === "r" ? "Recon" : type === "p" ? "Painter" : "Design"}
        </CustomText>
        <CustomText variant="body2">{getCurrentTime()}</CustomText>
      </Stack>
      <Box mt={2}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"flex-start"}
          mt={2}
          spacing={2}>
          <Stack width={"30%"}>
            <CustomText variant="body1">Priority</CustomText>
            <CustomInput
              fullWidth
              size="small"
              id="priority"
              name="priority"
              defaultValue={priorityLevel[0].value}
              value={data?.priority || priorityLevel[0].value}
              select
              onChange={handleChange}>
              {priorityLevel.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomInput>
          </Stack>
          <Stack width={"70%"}>
            <CustomText variant="body1">Assigned To</CustomText>
            <Autocomplete
              options={assigneeList}
              noOptionsText="Type Worker Name..."
              fullWidth
              autoComplete
              getOptionLabel={(option: { username: string; email: string }) =>
                option?.username
              }
              isOptionEqualToValue={(option, value) =>
                option.username === value.username
              }
              filterOptions={filterOptions}
              loading={isLoading}
              open={open}
              value={selectedUser}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              onChange={handleSelect}
              onInputChange={(_, newInputValue) =>
                handleInputChange(newInputValue)
              }
              id="assignee"
              componentName="assignee"
              renderInput={(params) => (
                <CustomInput
                  {...params}
                  id="assignee"
                  name="assignee"
                  placeholder="Search Assignee"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component={"li"}
                  {...props}
                  sx={{
                    backgroundColor: theme?.bgSecondary,
                    color: theme?.inputText,
                    "&.MuiAutocomplete-option.Mui-focused": {
                      backgroundColor: theme?.bgPrimary,
                    },
                  }}>
                  <List sx={{ width: "100%" }}>
                    <ListItem>
                      <ListItemText
                        primary={<CustomText>{option?.username}</CustomText>}
                        secondary={
                          <Fragment>
                            <CustomText>{option?.email}</CustomText>
                          </Fragment>
                        }
                      />
                    </ListItem>
                    <Divider sx={{ borderBottomWidth: 4 }} />
                  </List>
                </Box>
              )}
            />
          </Stack>
        </Stack>
        <Grid
          container
          spacing={2}
          mt={2}
          direction={"row"}
          justifyContent="space-evenly"
          alignItems="start">
          <Grid item sm={5}>
            <Stack>
              <CustomText variant="body1">Due Date (Automatic)</CustomText>
              <InputDate
                disabled
                id="due_date"
                name="due_date"
                onChange={handleChange}
                value={workDay?.EndDate}
              />
            </Stack>
          </Grid>
          <Grid item sm={5}>
            {type !== "p" ? (
              <Stack>
                <CustomText variant="body1">Cost</CustomText>
                <NewInputNominal
                  required
                  size="small"
                  id="cost"
                  name="cost"
                  value={data?.cost}
                  onChange={handleChange}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
              </Stack>
            ) : (
              <Stack>
                <CustomText variant="body1">Task Point</CustomText>
                <TaskPoint
                  required
                  size="small"
                  select
                  id="task_point"
                  name="task_point"
                  value={data?.task_point}
                  onChange={handleChange}
                />
              </Stack>
            )}
          </Grid>
          <Grid item sm={2}>
            <Stack>
              <CustomText variant="body1">Man Days</CustomText>
              <CustomInput
                required
                size="small"
                id="man_days"
                name="man_days"
                onChange={handleChange}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} display={"flex"} justifyContent={"flex-end"} gap={2}>
        <CustomButton variant="contained" type="submit" id="submit">
          Create
        </CustomButton>
        {/* <CustomButton
          variant="contained"
          id="complete"
          onClick={() => setOpenModalComplete(true)}
        >
          Complete
        </CustomButton> */}
      </Box>
      <ModalConfirm
        text="Are you sure you want to create task?"
        open={openModalCreate}
        onClose={() => setOpenModalCreate(false)}
        onSubmit={handleSubmit}
      />
      <ModalConfirm
        text="Are you sure you want to complete this step?"
        open={openModalComplete}
        onClose={() => setOpenModalComplete(false)}
        onSubmit={handleComplete}
      />
    </Box>
  );
}
