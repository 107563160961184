import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  Stack,
} from "@mui/material";
import CustomText from "src/components/main/CustomText";
import CustomButton from "src/components/main/CustomButton";
import ItemForm from "src/components/main/Item/Detail";
import CustomAccordion from "src/components/main/CustomAccordion";
import useThemeStore from "src/store/themeStore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CustomPaper from "src/components/main/CustomPaper";
import CustomInput from "src/components/main/CustomInput";
import { useState } from "react";
import CustomCard from "src/components/main/CustomCard";

interface Props {
  data: any;
  onChange: (e: any, index: number) => void;
  addItem: () => void;
  removeItem: (index: number) => void;
}
export default function ItemList({
  data,
  onChange,
  addItem,
  removeItem,
}: Props) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const { theme } = useThemeStore();

  const deleteConfirmation = (event: React.MouseEvent, index: number) => {
    event.stopPropagation();
    setOpenModal(true);
    setIndex(index);
  };

  const handleDelete = () => {
    removeItem?.(index);
    setOpenModal(false);
  };

  return (
    <Box>
      <CustomCard text={"Item List"}>
        <Box>
          {data?.map((item: any, index: number) => (
            <CustomAccordion key={index} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1-content"
                id="status"
                sx={{
                  borderRadius: "8px",
                }}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent={"space-between"}>
                  {`Item ${index + 1}`}
                  {data.length > 1 && (
                    <Button
                      sx={{
                        p: 0,
                      }}
                      startIcon={
                        <DeleteOutlineOutlinedIcon
                          sx={{ color: theme?.icon }}
                        />
                      }
                      onClick={(e) => deleteConfirmation(e, index)}
                    />
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ background: theme?.bgPaper }}>
                <ItemForm
                  data={item}
                  index={index}
                  onChange={onChange}
                  removeItem={removeItem}
                />
              </AccordionDetails>
            </CustomAccordion>
          ))}
        </Box>
        <Box textAlign="center">
          <CustomButton variant="contained" onClick={addItem}>
            Add More Item
          </CustomButton>
        </Box>
      </CustomCard>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <CustomPaper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            boxShadow: 24,
            px: 5,
            py: 2,
          }}>
          K<CustomText variant="h6">Confirm Delete Item {index + 1}</CustomText>
          <CustomText variant="body1">
            Are you sure to delete this item?
          </CustomText>
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"flex-end"}
            mt={2}>
            <CustomButton
              variant="outlined"
              customType="cancel"
              onClick={() => setOpenModal(false)}>
              Cancel
            </CustomButton>
            <CustomButton variant="contained" onClick={() => handleDelete()}>
              Delete
            </CustomButton>
          </Stack>
        </CustomPaper>
      </Modal>
    </Box>
  );
}
