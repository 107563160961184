import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { TextField } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface InputDateProps {
  id: string;
  name: string;
  disabled?: boolean;
  disablePast?: boolean;
  defaultValue?: string;
  minDate?: string;
  value?: string;
  onChange?: (e: any) => void;
}
export default function InputDate({
  id,
  name,
  disabled,
  disablePast,
  defaultValue,
  minDate,
  value,
  onChange,
}: InputDateProps) {
  const { theme } = useThemeStore();
  const handleChange = (date: any | null) => {
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: date ? date.format("YYYY-MM-DD") : "", // Format date if not null
        },
      });
    }
  };

  const disableKeyboardEntry = (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      <DatePicker
        disabled={disabled} // Use the passed "disabled" prop to control DatePicker
        disablePast={disablePast}
        format="YYYY-MM-DD"
        onChange={handleChange} // Pass handleChange directly to DatePicker
        minDate={minDate ? moment(minDate) : undefined}
        defaultValue={defaultValue ? moment(defaultValue) : null}
        value={value ? moment(value) : null}
        sx={{
          "& .MuiOutlinedInput-root": {
            background: `linear-gradient(22.5deg, ${theme?.inputPrimary}, ${theme?.inputSecondary})`,
            "& fieldset": {
              borderColor: theme?.inputBorder,
            },
            "&:hover fieldset": {
              borderColor: theme?.inputBorder,
            },
            "&.Mui-focused fieldset": {
              borderColor: theme?.inputBorder,
            },
            height: "40px",
          },
          "& .MuiInputBase-root": {
            color: theme.inputText,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: theme.inputTextDisabled, // Disabled input text color
            },
            "& .MuiSvgIcon-root": {
              color: theme?.icon,
            },
          },
        }}
        slotProps={{
          field: { clearable: true },
          textField: {
            size: "small",
            fullWidth: true,
            id: id,
            name: name,
            onBeforeInput: disableKeyboardEntry,
            required: true,
          },
        }}
      />
    </LocalizationProvider>
  );
}
