import { type FC } from "react";
import type { DropTargetMonitor } from "react-dnd";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { styled } from "@mui/material/styles";
import { Box, SxProps } from "@mui/material";
import useThemeStore from "src/store/themeStore";
import CustomText from "../CustomText";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PostAddIcon from "@mui/icons-material/PostAdd";
import NotificationAlert from "../Alert";

export interface DropBoxProps {
  sx?: SxProps;
  onDrop?: (item: File[]) => void;
  disabled?: boolean;
  id?: string;
}

export const DropBox: FC<DropBoxProps> = (props) => {
  const { sx, disabled, id = "dropbox", onDrop } = props ?? {};

  const { theme } = useThemeStore();

  const DropBoxContainer = styled(Box)(() => ({
    border: `2px dashed ${theme?.inputBorder}`,
    height: "15rem",
    width: "15rem",
    padding: "2rem",
    textAlign: "center",
    backgroundColor: theme?.bgDropzone,
    color: theme?.inputLabel,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "border-color 0.3s",
  }));

  const allowedTypes = ["image/jpeg", "image/png"];

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: any) {
        const files: File[] = Array.from(item.files);
        const validFiles = files.filter((file) =>
          allowedTypes.includes(file.type)
        );

        if (validFiles.length === 0) {
          NotificationAlert({
            message: "Only JPG, JPEG, PNG, PDF, and DOC files are allowed.",
            status: "error",
          });
        } else if (onDrop) {
          onDrop(validFiles);
        }
      },
      canDrop(item: any) {
        return true;
      },
      hover(item: any) {},
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props]
  );

  const isActive = canDrop && isOver;

  const handleClick = () => {
    document.getElementById(id)?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && onDrop) {
      const files = Array.from(event.target.files);
      const validFiles = files.filter((file) =>
        allowedTypes.includes(file.type)
      );

      if (validFiles.length === 0) {
        NotificationAlert({
          message: "Only JPG, JPEG, PNG, PDF, and DOC files are allowed.",
          status: "error",
        });
      } else {
        onDrop(validFiles);
      }
    }
  };

  return (
    <DropBoxContainer
      ref={drop}
      onClick={handleClick}
      sx={{
        ...(isActive && {
          borderColor: theme?.button100,
          backgroundColor: theme?.bgDropzoneActive,
        }),
        ...sx,
      }}>
      <input
        id={id}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {isActive ? (
        <Box textAlign={"center"}>
          <CustomText variant="body1">Release to drop</CustomText>
          <PostAddIcon />
        </Box>
      ) : (
        <Box textAlign={"center"}>
          <CustomText variant="body1">Drag File Here</CustomText>
          <UploadFileIcon />
        </Box>
      )}
    </DropBoxContainer>
  );
};
