import { Box, Grid } from "@mui/material";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CardNeedAction from "./components/CardNeedAction";
import Chart from "./components/Chart";
import RecentUpdates from "./components/RecentUpdates";

function Dashboard() {
  return (
    <Box>
      <PageTitle>Dashboard</PageTitle>
      <Box>
        <Grid container columnSpacing={2}>
          <Grid item lg={7} xl={8}>
            <Chart />
          </Grid>
          <Grid item lg={5} xl={4}>
            <RecentUpdates />
          </Grid>
        </Grid>
      </Box>

      <CardNeedAction />
    </Box>
  );
}

export default Dashboard;
