import { Box, Card, InputAdornment, Modal, Stack } from "@mui/material";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { MouseEvent, useEffect, useState } from "react";
import { approveLeave, InquiryLeaveList, rejectLeave } from "src/api/user";
import NotificationAlert from "src/components/main/Alert";
import ApproveReject from "src/components/main/ApproveReject";
import CustomButton from "src/components/main/CustomButton";
import CustomInput from "src/components/main/CustomInput";
import SearchIcon from "@mui/icons-material/Search";

import CustomPaper from "src/components/main/CustomPaper";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PageTitle from "src/components/main/CustomText/PageTitle";
import useThemeStore from "src/store/themeStore";
import { useDebouncedCallback } from "use-debounce";
import { LEAVE_COLUMNS } from "src/constants/user";

export default function LeaveList() {
  const { theme } = useThemeStore();
  const [leaves, setLeaves] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({ data: {}, status: "" });
  const [notes, setNotes] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const navigate = useNavigate();

  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await InquiryLeaveList({
        status: filter.status,
        id: filter.key,
        order_key: filter.order_key,
        sort_type: filter.sort_type,
        start_date: filter.start_date,
        end_date: filter.end_date,
        page: filter.page,
        limit: filter.limit,
      });
      response.data?.data ? formatData(response?.data?.data) : setLeaves([]);
      // response?.data?.data ? setLeaves([...response.data.data]) : setLeaves([]);
      handleFilterChange("total_page", response?.data?.total_page);
      response?.data?.totalPage < filter.page && handleFilterChange("page", 1);
    } catch (error: any) {
      console.error("Error fetching item data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [    filter.status,
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
    filter.order_date_start,
    filter.order_date_end,]);

  const onClickDetail = (id: string) => {
    navigate(`/leave/${id}`);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async () => {
    const req = {
      leave_id: modalData.data.id,
      notes: notes,
    };
    modalData.status === "approve"
      ? await approveLeave(req)
      : await rejectLeave(req);

    setOpenModal(false);
    fetchData();
  };

  const ActionClick = (event: MouseEvent, data: any, status: string) => {
    event.stopPropagation();
    setOpenModal(true);
    setModalData({ data, status });
  };

  const formatData = (data: any) => {
    const processedData = data?.map((item: any) => {
      return {
        ...item,
        start_date: item?.start_date?.split("T")[0],
        end_date: item?.end_date?.split("T")[0],
        request_date: item?.created_at?.split("T")[0],
        actions: (
          // <Stack direction={"row"} spacing={1} justifyContent={"center"}>
          //   <ApproveReject
          //     data={item.id}
          //     action="approve"
          //     handleClick={(e) => ActionClick(e, item, "approve")}
          //   />
          //   <ApproveReject
          //     data={item.id}
          //     action="reject"
          //     handleClick={(e) => ActionClick(e, item, "reject")}
          //   />
          // </Stack>
          <Stack direction={"row"} spacing={1} justifyContent={"center"}>
          {item.is_active ? (
            <>
              <ApproveReject
                data={item.id}
                action="approve"
                handleClick={(e) => ActionClick(e, item, "approve")}
              />
              <ApproveReject
                data={item.id}
                action="reject"
                handleClick={(e) => ActionClick(e, item, "reject")}
              />
            </>
          ) : (
            <span>{item?.status}</span>
          )}
        </Stack>
        ),
      };
    });

    setLeaves(processedData);
  };

  const filterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleFilterChange("key", value);
    const filtered = leaves?.filter((item: any) => {
      return item?.nama_user?.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(filtered);
  };

  const debouncedFilter = useDebouncedCallback(filterSearch, 1000);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e);
  };

  return (
    <Box>
      <PageTitle>Leave</PageTitle>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          borderBottom={`1px solid ${theme?.border}`}
          alignContent={"center"}
          p={2}
        >
          <Box sx={{ display: "inline-block", alignContent: "center" }}>
            <CustomText variant="subtitle1" fontWeight={700} align="center">
              Leave Request List
            </CustomText>
          </Box>
          <Box
            display={"flex"}
            gap={2}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            <CustomInput
              placeholder="Search"
              size="small"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme?.icon }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <CustomTable
          columns={LEAVE_COLUMNS}
          data={filter.key ? filteredData : leaves}
          onClickDetail={onClickDetail}
          filter={filter}
          onChangeFilter={handleFilterChange}
        />
      </Card>
      <Modal open={openModal} onClose={handleCloseModal}>
        <CustomPaper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            height: "auto",
            boxShadow: 24,
            px: 5,
            py: 2,
          }}
        >
          <Stack alignItems="start" direction="row" gap={2}>
            {modalData.status === "approve" ? (
              <CheckCircleIcon color="success" fontSize="large" />
            ) : (
              <CancelIcon color="error" fontSize="large" />
            )}
            <CustomText variant="h6" className="capitalize" gutterBottom>
              {modalData.status} Leave Request
            </CustomText>
          </Stack>
          <CustomText variant="body1" mt={2} fontWeight={"bold"}>
            User Notes
          </CustomText>

          <CustomText variant="body2" mb={4}>
            {modalData.data.user_notes}
          </CustomText>
          <CustomInput
            id="leave"
            label="Leave Notes Admin"
            fullWidth
            multiline
            rows={4}
            onChange={(e) => setNotes(e.target.value)}
          />
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"flex-end"}
            mt={2}
          >
            <CustomButton
              variant="outlined"
              customType="cancel"
              onClick={handleCloseModal}
            >
              Close
            </CustomButton>
            <CustomButton variant="contained" onClick={handleSubmit}>
              Submit
            </CustomButton>
          </Stack>
        </CustomPaper>
      </Modal>
    </Box>
  );
}
