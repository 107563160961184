import {
  currencies,
  designType,
  orderFrom,
  paymentMethod,
  serviceType,
} from "src/utils/paramData";
import { create } from "zustand";

interface Shipment {
  province: string;
  city: string;
  country: string;
  village: string;
  district: string;
  address: string;
  postal_code: string;
  recipient_name: string;
  recipient_contact: string;
  type_shipment: string;
  codeCountry:string;
}

interface NewShipment {
  isNewShipment: boolean;
  shipment: Shipment;
}

interface Order {
  order_from: string;
  deadline: string;
  start_date: string;
  end_date: string;
  amount: string;
  notes: string;
  referal_code: string;
  currency: string;
  payment_method: string;
  payment_curr: string;
  payment_nominal: string;
  status: string;
  tanggal_pelunasan: string;
  status_order:string
}

interface OrderState {
  customer: Customer;
  order: Order;
  itemList: any[];
  shipment: Shipment;
  newShipment: NewShipment;
  isOrderComplete: boolean;
  setCustomer: (customer: OrderState["customer"]) => void;
  setOrder: (order: OrderState["order"]) => void;
  setItemList: (itemList: OrderState["itemList"]) => void;
  setShipment: (shipment: OrderState["shipment"]) => void;
  setNewShipment: (shipment: OrderState["newShipment"]) => void;
  setIsOrderComplete: (isOrderComplete: boolean) => void;
  resetOrderStore: () => void;
}

const initialItemList = [
  {
    design_type: designType.length > 0 ? designType[0].value : "",
    service_type: serviceType.length > 0 ? serviceType[0].value : "",
    from_client: false,
    leather_from_client: false,
    supplier_deadline:"",
  },
];

const initialShipment: Shipment = {
  province: "",
  city: "",
  country: "",
  village: "",
  district: "",
  address: "",
  postal_code: "",
  recipient_name: "",
  recipient_contact: "",
  type_shipment: "",
  codeCountry:""
};

const initialCustomer: Customer = {
  id: "",
  name: "",
  phone_number: "",
  email: "",
  birth_date: "",
  instagram: "",
  tiktok: "",
  IsActive: false,
  last_order_date: "",
  total_orders: 0,
  total_spent: "",
};

const initialOrder: Order = {
  order_from: orderFrom.length > 0 ? orderFrom[0].value : "",
  deadline: "",
  start_date: "",
  end_date: "",
  amount: "",
  notes: "",
  referal_code: "",
  currency: currencies.length > 0 ? currencies[0].value : "",
  payment_method: paymentMethod.length > 0 ? paymentMethod[0].value : "",
  payment_curr: currencies.length > 0 ? currencies[0].value : "",
  payment_nominal: "",
  status: "",
  tanggal_pelunasan: "",
  status_order:""
};

export const useOrderStore = create<OrderState>()((set) => ({
  customer: initialCustomer,
  order: initialOrder,
  itemList: initialItemList,
  shipment: initialShipment,
  newShipment: {
    isNewShipment: false,
    shipment: initialShipment,
  },
  isOrderComplete: false,
  setCustomer: (customer: OrderState["customer"]) =>
    set(() => ({ customer: customer })),
  setOrder: (order: OrderState["order"]) => set(() => ({ order: order })),
  setItemList: (itemList: OrderState["itemList"]) =>
    set(() => ({ itemList: itemList })),
  setShipment: (shipment: OrderState["shipment"]) =>
    set(() => ({ shipment: shipment })),
  setNewShipment: (shipment: OrderState["newShipment"]) =>
    set(() => ({ newShipment: shipment })),
  setIsOrderComplete: (isOrderComplete: boolean) =>
    set(() => ({ isOrderComplete: isOrderComplete })),
  resetOrderStore: () =>
    set({
      customer: initialCustomer,
      order: initialOrder,
      itemList: initialItemList,
      shipment: initialShipment,
      newShipment: {
        isNewShipment: false,
        shipment: initialShipment,
      },
    }),
}));
