import {
  Box,
  Stack,
  Grid,
  FormControlLabel,
  InputAdornment,
  Autocomplete,
  createFilterOptions,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import { TypeService, TypeDesign } from "src/components/main/Item/Type";
import CustomText from "src/components/main/CustomText";
import CustomInput from "src/components/main/CustomInput";
import CustomFormGroup from "src/components/main/CustomFormGroup";
import useThemeStore from "src/store/themeStore";
import Radio from "@mui/material/Radio";
import {
  dompetSize,
  itemType,
  jaketSize,
  shoesSize,
  serviceType,
  getCurrencySymbol,
} from "src/utils/paramData";
import NewInputNominal from "../../NewInputNominal";
import { Fragment, useEffect, useMemo, useState } from "react";
import InputDate from "../../Datepicker";
import { useOrderStore } from "src/store/orderStore";
import CustomButton from "../../CustomButton";
import ModalConfirm from "../../ModalConfirm";
import { useNavigate } from "react-router-dom";
import { splitItem } from "src/api/order";

const filter = createFilterOptions();

interface Props {
  data: any;
  formData?: any;
  disabled?: boolean;
  index: number;
  onChange: (e: any, index: number) => void;
  removeItem?: (index: number) => void;
  isDetail?: boolean;
}
export default function ItemForm({
  data,
  disabled,
  index,
  onChange,
  removeItem,
  formData,
  isDetail,
}: Props) {
  const { theme } = useThemeStore();
  const { order } = useOrderStore();
  const [splitModal, setSplitModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const numericGreaterThan = (numStr1: string, numStr2: string) => {
    if (order?.currency !== "USD") {
      const num1 = numStr1 ? Number(numStr1) : 0;
      const num2 = numStr2 ? Number(numStr2) : 0;

      return num1 > num2;
    }
  };

  const handleSplit = async () => {
    const req: SplitItem = {
      item_id: data?.id,
      notes: "",
    };
    await splitItem(req);
    setSplitModal(false);
    navigate("/order");
  };
  useEffect(() => {
    if (order?.currency && !isDetail) {
      data.cost = 0;
      onChange({ target: { name: "cost", value: "0" } }, index);
      onChange({ target: { name: "starting_price", value: "0" } }, index);
    }
  }, [order?.currency]);

  const renderSize = useMemo(() => {
    if (data?.name === itemType[0].value) {
      onChange({ target: { name: "size", value: shoesSize[0] } }, index);
      return shoesSize.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ));
    }

    if (data?.name === itemType[1].value) {
      onChange({ target: { name: "size", value: jaketSize[0] } }, index);
      return jaketSize.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ));
    }

    if (data?.name === itemType[2].value) {
      onChange({ target: { name: "size", value: dompetSize[0] } }, index);
      return dompetSize.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ));
    }

    if (!data?.name) {
      onChange({ target: { name: "size", value: "" } }, index);
    }
    // return null;
  }, [data?.name, itemType]);
  const isCustomDesign =
    data?.design_type === "0707630e-b06a-4a35-8729-34305d1e8f1a";
  const isIDR = order?.currency === "IDR";
  const isCostInvalid = isCustomDesign && data?.cost < (isIDR ? 500000 : 50);

  return (
    <Box>
      <Grid container columnSpacing={4}>
        <Grid item sm={6} width={"100%"}>
          <Stack my={"10px"}>
            <CustomText variant="body1" required={!data?.id}>
              Item Type
            </CustomText>
            <Autocomplete
              value={data?.name || ""}
              onChange={(event, newValue) => {
                let valueToSend;

                if (typeof newValue === "string") {
                  valueToSend = newValue;
                } else if (newValue && newValue.inputValue) {
                  valueToSend = {
                    label: newValue?.inputValue,
                    value: newValue?.inputValue,
                  };
                } else {
                  valueToSend = newValue ? newValue : "";
                }
                onChange(
                  { target: { name: "name", value: valueToSend.value } },
                  index
                );
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.value
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    value: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              autoComplete
              disabled={disabled}
              id="name"
              options={itemType}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.value;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.value}</li>
              )}
              renderInput={(params) => (
                <CustomInput
                  {...params}
                  required
                  disabled={disabled}
                  value={data?.name}
                  onChange={(event) => onChange(event, index)}
                  size="small"
                  id="name"
                  name="name"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>{params.InputProps.endAdornment}</Fragment>
                    ),
                  }}
                />
              )}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1" required={!data?.id}>
              Service Type
            </CustomText>
            <TypeService
              disabled={disabled}
              value={data?.service_type}
              id="service_type"
              name="service_type"
              onChange={(event) => onChange(event, index)}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1" required={!data?.id}>
              Size
            </CustomText>
            <CustomInput
              required
              disabled={disabled}
              value={data?.size}
              onChange={(event) => onChange(event, index)}
              size="small"
              id="size"
              name="size"
              type="string"
              select={itemType.some((type) => type.value === data?.name)}
              InputProps={{
                endAdornment:
                  data?.name === itemType[0].value ? (
                    <CustomText
                      className="pr-[24px]"
                      variant="body1"
                      gutterBottom={false}>
                      EUR
                    </CustomText>
                  ) : (
                    ""
                  ),
              }}>
              {renderSize}
            </CustomInput>
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1" required={!data?.id}>
              Item Price
            </CustomText>
            <NewInputNominal
              required
              disabled={disabled}
              value={data?.cost?.toString()}
              onChange={(event) => onChange(event, index)}
              size="small"
              id="cost"
              name="cost"
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getCurrencySymbol(order?.currency)}
                  </InputAdornment>
                ),
              }}
              error={isCostInvalid}
              helperText={
                isCostInvalid
                  ? isIDR
                    ? "The price of the item must be greater than Rp.500,000 if the design type is a custom design"
                    : "The price of the item must be greater than $50 if the design type is a custom design"
                  : ""
              }
            />
          </Stack>
          {data?.from_client === false && (
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!data?.id}>
                Item Cost
              </CustomText>
              <NewInputNominal
                required
                error={numericGreaterThan(
                  data?.starting_price?.toString(),
                  data?.cost?.toString()
                )}
                helperText={
                  numericGreaterThan(
                    data?.starting_price?.toString(),
                    data?.cost?.toString()
                  )
                    ? "Item Cost Must Be lower than Item Price"
                    : ""
                }
                disabled={disabled}
                value={data?.starting_price?.toString()}
                onChange={(event) => onChange(event, index)}
                size="small"
                id="starting_price"
                name="starting_price"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
              />
            </Stack>
          )}
          {!data?.id && (
            <Stack my="10px">
              <CustomText variant="body1" required={!data?.id}>
                Base From
              </CustomText>
              <CustomFormGroup>
                <RadioGroup
                  row
                  name="from_client"
                  defaultValue={data?.from_client ? "client" : "supplier"}
                  onChange={(event) => onChange(event, index)}>
                  <FormControlLabel
                    value="supplier"
                    control={<Radio />}
                    label="Supplier"
                    disabled={disabled}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: theme?.inputLabel,
                        "&.Mui-disabled": {
                          color: theme?.inputLabel,
                        },
                      },
                    }}
                  />
                  <FormControlLabel
                    value="client"
                    control={<Radio />}
                    label="Client"
                    disabled={disabled}
                  />
                </RadioGroup>
              </CustomFormGroup>
            </Stack>
          )}
          {data?.id && (
            <Stack my={"10px"}>
              <CustomText variant="body1">
                Base From {data?.from_client}
              </CustomText>
              <CustomFormGroup>
                <RadioGroup
                  row
                  name="from_client"
                  value={data?.from_client ? "client" : "supplier"}
                  //  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="supplier"
                    control={<Radio />}
                    label="Supplier"
                    disabled
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: theme?.inputLabel,
                        "&.Mui-disabled": {
                          color: theme?.inputLabel,
                        },
                      },
                    }}
                  />
                  <FormControlLabel
                    value="client"
                    control={<Radio />}
                    label="Client"
                    disabled
                  />
                </RadioGroup>
              </CustomFormGroup>
            </Stack>
          )}
        </Grid>
        <Grid item sm={6} width={"100%"}>
          <Stack my={"10px"}>
            <CustomText variant="body1" required={!data?.id}>
              Brand
            </CustomText>
            <CustomInput
              required
              disabled={disabled}
              value={data?.brand}
              onChange={(event) => onChange(event, index)}
              size="small"
              id="brand"
              name="brand"
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1" required={!data?.id}>
              Design Type
            </CustomText>
            <TypeDesign
              id="design_type"
              name="design_type"
              disabled={disabled}
              value={data?.design_type}
              onChange={(event) => onChange(event, index)}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1" required={!data?.id}>
              Color / Type
            </CustomText>
            <CustomInput
              required
              disabled={disabled}
              value={data?.color}
              onChange={(event) => onChange(event, index)}
              size="small"
              id="color"
              name="color"
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1" required={!data?.id}>
              Design Name
            </CustomText>
            <CustomInput
              required
              disabled={disabled}
              value={data?.design_name}
              onChange={(event) => onChange(event, index)}
              size="small"
              id="design_name"
              name="design_name"
            />
          </Stack>
          {data?.from_client === false && (
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!data?.id}>
                Supplier Deadline
              </CustomText>
              <InputDate
                name="supplier_deadline"
                id="supplier_deadline"
                value={data?.supplier_deadline || data?.deadline_supplier}
                onChange={(event) => onChange(event, index)}
                disablePast
                disabled={disabled}
              />
            </Stack>
          )}
          {(data?.service_type === serviceType[1].value ||
            data?.service_type === serviceType[2].value) && (
            <>
              <Stack my={"10px"}>
                <CustomText variant="body1" required={!data?.id}>
                  Leather Type
                </CustomText>
                <CustomInput
                  required
                  disabled={disabled}
                  value={data?.leather_type}
                  onChange={(event) => onChange(event, index)}
                  size="small"
                  id="leather_type"
                  name="leather_type"
                />
              </Stack>

              <Stack my={"10px"}>
                <CustomText variant="body1" required={!data?.id}>
                  Leather From
                </CustomText>
                <CustomFormGroup>
                  <RadioGroup
                    row
                    name="leather_from_client"
                    defaultValue={
                      data?.leather_from_client ? "client" : "supplier"
                    }
                    onChange={(event) => onChange(event, index)}>
                    <FormControlLabel
                      value="supplier"
                      control={<Radio />}
                      label="Supplier"
                      disabled={disabled}
                    />
                    <FormControlLabel
                      value="client"
                      control={<Radio />}
                      label="Client"
                      disabled={disabled}
                    />
                  </RadioGroup>
                </CustomFormGroup>
              </Stack>
            </>
          )}
        </Grid>
      </Grid>
      <Stack my={"10px"}>
        <CustomText variant="body1">Additional Notes</CustomText>
        <CustomInput
          disabled={disabled}
          value={data?.notes}
          onChange={(event) => onChange(event, index)}
          size="small"
          id="notes"
          name="notes"
          multiline
          rows={4}
        />
      </Stack>
      {data?.id && formData?.length !== 1 && (
        <Grid container>
          <Grid item lg={12} md={12} sm={12}></Grid>{" "}
          {/* First empty Grid item */}
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            justifyContent="flex-end">
            {/* Aligns content to the right */}
            <Stack width={"20%"}>
              <CustomButton
                variant="contained"
                onClick={() => setSplitModal(true)}>
                Split Item
              </CustomButton>
            </Stack>
          </Grid>
        </Grid>
      )}
      <ModalConfirm
        text="Are you sure you want to split this order?"
        open={splitModal}
        onClose={() => setSplitModal(false)}
        onSubmit={handleSplit}
      />
    </Box>
  );
}
