import { Breadcrumbs, Typography, Box } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { roleData } from "src/utils/roleData";
import useThemeStore from "src/store/themeStore";
import { useCallback } from "react";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import AlarmOffOutlinedIcon from "@mui/icons-material/AlarmOffOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PaymentIcon from "@mui/icons-material/Payment";
import { hexToRgba } from "src/utils/utils";

function BreadCrumbs() {
  const location = useLocation();
  const { pathname } = location ?? {};
  const { theme } = useThemeStore();
  const segments = pathname?.substring(1, 100)?.split("/");
  const navigate = useNavigate();

  const buildUrl = (segments: string[]) => {
    return "/" + segments.join("/");
  };

  const handleBack = () => {
    navigate(buildUrl(segments?.slice(0, -1)));
  };

  const exceptionMenu = (menu: string) => {
    return roleData.some((role) => role.menu === `/${menu}` && role.hasSubmenu);
  };

  const formatSegment = (segment: string) =>
    segment === "" ? "home" : segment.replace(/-/g, " ").trim();

  const renderIcon = useCallback(
    (page: string) => {
      if (page === "/") {
        return (
          <DesktopWindowsOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "order") {
        return (
          <LocalMallOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "item") {
        return (
          <WidgetsOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "task") {
        return (
          <InventoryOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "task/task-list") {
        return (
          <ChecklistOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "task/management") {
        return (
          <ManageHistoryOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "user") {
        return (
          <PeopleAltOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "customer") {
        return (
          <SupportAgentOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "finance") {
        return (
          <AccountBalanceOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "finance/monthly-recap") {
        return (
          <AssessmentOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "finance/salary") {
        return (
          <PaidOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "finance/incentive") {
        return (
          <CurrencyExchangeIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "finance/debt") {
        return (
          <AssessmentOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "company") {
        return (
          <BusinessOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "company/company-settings") {
        return (
          <SettingsIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "company/collective-leave") {
        return (
          <NotificationsActiveIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "reimbursement") {
        return (
          <PaymentIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "reimbursement/purchasing") {
        return (
          <ShoppingCartIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      if (page === "leave") {
        return (
          <AlarmOffOutlinedIcon
            sx={{ color: theme?.icon, mr: 0.5 }}
            fontSize="inherit"
          />
        );
      }
      return (
        <ConstructionOutlinedIcon
          sx={{ color: theme?.icon, mr: 0.5 }}
          fontSize="inherit"
        />
      );
    },
    [roleData]
  );

  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        position: "sticky",
        paddingLeft: "10px",
        paddingY: "1px",
        backgroundColor: theme?.bgPrimary,
        top: 64,
        zIndex: 1,
        borderBottom: `1px solid ${hexToRgba(theme?.border, 1)}`,
      }}>
      <Box className="w-full pl-4 py-1 rounded-[4px]">
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="/"
          sx={{ color: theme?.inputLabel }}>
          {segments?.map((segment, i) => {
            const url = buildUrl(segments.slice(0, i + 1));
            const isLast = i === segments.length - 1;
            const showLink = !exceptionMenu(segments[0]) || i === 1;

            return showLink ? (
              <Link key={i} to={url}>
                <Typography
                  className="capitalize"
                  sx={{
                    "&:hover": {
                      textDecoration: isLast ? "none" : "underline",
                    },
                    cursor: isLast ? "text" : "pointer",
                  }}>
                  {/* {renderIcon(segment)} */}
                  {segment}
                </Typography>
              </Link>
            ) : (
              <Typography key={i} className="capitalize">
                {segment}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Box>
    </Box>
  );
}

export default BreadCrumbs;
