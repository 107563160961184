import { Box, IconButton, Popover, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import ILogo from "../../../assets/ILogo";
import useThemeStore from "src/store/themeStore";
import { DRAWER_WIDTH } from "src/constants/ui";
import { useMemo, useState } from "react";
import CustomText from "src/components/main/CustomText";
import { useUserStore } from "src/store/userStore";
import { useNavigate } from "react-router-dom";
import { useParameterStore } from "src/store/parameterStore";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface INavbar {
  open: boolean;
  handleDrawerOpen: () => void;
}

function Navbar({ open, handleDrawerOpen }: INavbar) {
  const { theme: myTheme } = useThemeStore();
  const { reset } = useUserStore();
  const navigate = useNavigate();

  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenPopover(!openPopover);
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("param-state");
    localStorage.removeItem("user-state");
    reset();
    navigate("/login");
  };

  const displayName = useUserStore((state) => state.displayName);
  const AppBar = useMemo(
    () =>
      styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== "open",
      })<AppBarProps>(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        backgroundImage: `linear-gradient(to right, ${myTheme?.navbarPrimary}, ${myTheme?.navbarSecondary})`,
        ...(open
          ? {
              marginLeft: DRAWER_WIDTH,
              width: `calc(100% - ${DRAWER_WIDTH}px)`,
              transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }
          : {
              transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: `calc(100% - 0px)`,
            }),
      })),
    []
  );
  return (
    <AppBar position="absolute" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}>
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
          }}>
          <ILogo width={40} height={40} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                opacity: 0.5,
              },
            }}
            className="space-x-4"
            onClick={(e) => handleClick(e)}>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Super Admin
            </Typography>
            <AccountCircleOutlinedIcon fontSize="large" />
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{ overflow: "auto", borderRadius: 2 }}>
              <Box
                px={2}
                py={1}
                width={150}
                sx={{
                  cursor: "pointer",
                  backgroundColor: myTheme?.bgPrimary,
                  overflow: "auto",
                }}
                onClick={handleLogout}>
                <CustomText variant="body1">Logout</CustomText>
              </Box>
            </Popover>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
