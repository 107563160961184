export const ITEM_COLUMNS = [
  { title: "Item ID", field: "id", sortable: true, minWidth: 180 },
  { title: "Order ID", field: "order_id", sortable: true, minWidth: 180 },
  { title: "Item Price", field: "cost", sortable: true, align: "right" },
  { title: "Deadline", field: "end_date", sortable: true, minWidth: 150 },
  { title: "Status Detail", field: "status", sortable: true },
  { title: "PIC ROLE", field: "pic", sortable: false },
  { title: "PIC USER", field: "pic_user_name", sortable: false },
  { title: "Design Name", field: "design_name", sortable: false },
];
