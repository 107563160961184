import { Stack, Grid, MenuItem, InputAdornment } from "@mui/material";
import { userLevel, userRole, userStatus } from "src/utils/paramData";
import CustomText from "src/components/main/CustomText";
import CustomInput from "src/components/main/CustomInput";
import NewInputNominal from "src/components/main/NewInputNominal";
import CustomCard from "src/components/main/CustomCard";
import Password from "./Password";
import { useParameterStore } from "src/store/parameterStore";
import useThemeStore from "src/store/themeStore";
import Leave from "src/components/main/LeaveDetail";
import level from "src/assets/level.json";


type TAction = "create" | "update";
interface Props {
  title: string;
  isEdit: boolean;
  formData?: User;
  action: TAction;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function UserForm(props: Props) {
  const { title, isEdit, formData, action, error, onChange } = props;
  const { theme } = useThemeStore();

  const { role } = useParameterStore();
  return (
    <CustomCard text={title}>
      <Grid
        container
        spacing={4}
        direction={"row"}
        justifyContent="space-evenly"
        alignItems="flex-start">
        <Grid item sm={12} md={6} lg={4}>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body1" required={isEdit}>
                Username
              </CustomText>
              <CustomInput
                disabled={!isEdit}
                value={formData?.username}
                onChange={onChange}
                required
                size="small"
                id="username"
                name="username"
                error={formData?.username?.includes(" ")}
                helperText={
                  formData?.username?.includes(" ")
                    ? "Username cannot contain spaces"
                    : ""
                }
              />
            </Stack>
            <Stack>
              <CustomText variant="body1" required={isEdit}>
                Email
              </CustomText>
              <CustomInput
                disabled={!isEdit}
                value={formData?.email || ""}
                onChange={onChange}
                required
                size="small"
                id="email"
                name="email"
                error={
                  !!formData?.email &&
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
                }
                helperText={
                  !!formData?.email &&
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
                    ? "Email is not valid"
                    : ""
                }
              />
            </Stack>
            {action === "create" && (
              <Stack>
                <CustomText variant="body1" required={isEdit}>
                  Password
                </CustomText>
                <Password
                  disabled={!isEdit}
                  value={formData?.password}
                  onChange={onChange}
                  required
                  size="small"
                  id="password"
                  name="password"
                  error={!!error}
                  helperText={error}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      background: theme?.inputPrimary,
                      "& fieldset": {
                        borderColor: error
                          ? theme?.button100
                          : theme?.inputBorder,
                      },
                      "&:hover fieldset": {
                        borderColor: error
                          ? theme?.button100
                          : theme?.inputBorder,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: error
                          ? theme?.button100
                          : theme?.inputBorder,
                      },
                    },
                    "& .MuiFormHelperText-root": { color: theme?.button100 },
                  }}
                />
              </Stack>
            )}
            { action !== "create"  &&(
                          <Stack>
                          <CustomText variant="body1" required={isEdit}>
                            Phone Number
                          </CustomText>
                          <CustomInput
                            disabled={!isEdit}
                            value={formData?.phone_number}
                            onChange={onChange}
                            required
                            size="small"
                            id="phone_number"
                            name="phone_number"
                            error={
                              (formData?.phone_number?.toString() || "").length > 0 &&
                              (formData?.phone_number?.toString() || "").length < 9
                            }
                            helperText={
                              (formData?.phone_number?.toString() || "").length > 0 &&
                              (formData?.phone_number?.toString() || "").length < 9
                                ? "Phone Number Must Be At Least 9 Characters"
                                : ""
                            }
                          />
                        </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body1" required={isEdit}>
                Display Name
              </CustomText>
              <CustomInput
                disabled={!isEdit}
                value={formData?.display_name}
                onChange={onChange}
                required
                size="small"
                id="display_name"
                name="display_name"
                error={
                  !!formData?.display_name &&
                  /[^a-zA-Z0-9\s]/.test(formData.display_name)
                }
                helperText={
                  !!formData?.display_name &&
                  /[^a-zA-Z0-9\s]/.test(formData.display_name)
                    ? "Display name cannot contain special characters."
                    : ""
                }
              />
            </Stack>
            <Stack>
              <CustomText variant="body1" required={isEdit}>
                Salary
              </CustomText>
              <NewInputNominal
                size="small"
                id="salary"
                name="salary"
                disabled={!isEdit}
                value={formData?.salary?.toString() || "0"}
                onChange={onChange}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
              />
            </Stack>
            { action === "create" && (
                          <Stack>
                          <CustomText variant="body1" required={isEdit}>
                            Phone Number
                          </CustomText>
                          <CustomInput
                            disabled={!isEdit}
                            value={formData?.phone_number}
                            onChange={onChange}
                            required
                            size="small"
                            id="phone_number"
                            name="phone_number"
                            error={
                              (formData?.phone_number?.toString() || "").length > 0 &&
                              (formData?.phone_number?.toString() || "").length < 9
                            }
                            helperText={
                              (formData?.phone_number?.toString() || "").length > 0 &&
                              (formData?.phone_number?.toString() || "").length < 9
                                ? "Phone Number Must Be At Least 9 Characters"
                                : ""
                            }
                          />
                        </Stack>
            )}
                {action!== "create" && (
                <Stack>
                <CustomText variant="body1" required={isEdit}>
                  Address
                </CustomText>
                <CustomInput
                  disabled={!isEdit}
                  value={formData?.address}
                  onChange={onChange}
                  required
                  size="small"
                  id="address"
                  name="address"
                />
              </Stack>
              )}
            
          </Stack>
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body1" required={isEdit}>
                Level
              </CustomText>
              <CustomInput
                disabled={!isEdit}
                onChange={onChange}
                required
                size="small"
                id="level"
                name="level"
                select
                value={formData?.level ? formData?.level : level[0]?.value}
                defaultValue={level[0]?.value}>
                {level.map((option) => (
                  <MenuItem key={option?.name} value={option?.name}>
                    {option?.name}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
            <Stack>
              <CustomText variant="body1" required={isEdit}>
                Role
              </CustomText>
              <CustomInput
                disabled={!isEdit}
                onChange={onChange}
                required
                size="small"
                id="role"
                name="role"
                select
                value={formData?.role ? formData?.role : role[0]?.Id}
                defaultValue={role[0]?.Id}>
                {role.map((option) => (
                  <MenuItem key={option?.Id} value={option?.Id}>
                    {option?.Name}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
              {action === "create" && (
                <Stack>
                <CustomText variant="body1" required={isEdit}>
                  Address
                </CustomText>
                <CustomInput
                  disabled={!isEdit}
                  value={formData?.address}
                  onChange={onChange}
                  required
                  size="small"
                  id="address"
                  name="address"
                />
              </Stack>
              )}
            </Stack>
        </Grid>
      </Grid>
    </CustomCard>
  );
}
