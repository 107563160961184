import {
  AccordionSummary,
  Box,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputDate from "../Datepicker";
import CustomCheckbox from "../CustomCheckbox";
import useThemeStore from "src/store/themeStore";
import { useFilterStore } from "src/store/filterStore";
import { useEffect, useState } from "react";
import CustomText from "../CustomText";
import { useLocation } from "react-router-dom";
import FilterAccordion from "./FilterAccordion";
import FilterAccordionDetail from "./FilterAccordionDetail";
import FilterHeader from "./FilterHeader";
import SupplierAutocomplete from "../CustomAutocomplete/SupplierAutocomplete";

interface Props {
  statuses: string[];
  filter: FilterTable;
  onChangeFilter: (name: string, value: any) => void;
}

export default function FilterTable({
  statuses,
  filter,
  onChangeFilter = () => {},
}: Props) {
  const { theme } = useThemeStore();
  const page = useLocation().pathname.split("/").pop();
  const [showMore, setShowMore] = useState<boolean>(false);
  useFilterStore();
  const [tempStartDate, setTempStartDate] = useState<string>(filter.start_date);
  const [tempEndDate, setTempEndDate] = useState<string>(filter.end_date);
  const [tempOrderDateStart, setTempOrderDateStart] = useState<string>(
    filter.order_date_start
  );
  const [tempOrderDateEnd, setTempOrderDateEnd] = useState<string>(
    filter.order_date_end
  );

  useEffect(() => {
    if (tempStartDate && tempEndDate) {
      onChangeFilter("start_date", tempStartDate);
      onChangeFilter("end_date", tempEndDate);
    }
    if (tempStartDate === "" && tempEndDate === "") {
      onChangeFilter("start_date", "");
      onChangeFilter("end_date", "");
    }
  }, [tempStartDate, tempEndDate]);

  useEffect(() => {
    if (tempOrderDateStart && tempOrderDateEnd) {
      onChangeFilter("order_date_start", tempOrderDateStart);
      onChangeFilter("order_date_end", tempOrderDateEnd);
    }
    if (tempOrderDateStart === "" && tempOrderDateEnd === "") {
      onChangeFilter("order_date_start", "");
      onChangeFilter("order_date_end", "");
    }
  }, [tempOrderDateStart, tempOrderDateEnd]);

  const handleFilterStatus = (event: any) => {
    const filterStatus = event.target;
    if (filterStatus.checked) {
      onChangeFilter("status", [...filter.status, filterStatus.name]);
    } else {
      onChangeFilter(
        "status",
        filter.status.filter((status) => status !== filterStatus.name)
      );
    }
  };

  const handleDueDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "start_date") {
      setTempStartDate(value);
      value > tempEndDate && tempEndDate !== "" && setTempEndDate(value);
    }

    if (name === "end_date") {
      setTempEndDate(value);
    }
  };

  const handleOrderDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "order_date_start") {
      setTempOrderDateStart(value);
      value > tempOrderDateEnd &&
        tempOrderDateEnd !== "" &&
        setTempOrderDateEnd(value);
    }

    if (name === "order_date_end") {
      setTempOrderDateEnd(value);
    }
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Box>
      <FilterHeader />
      <FilterAccordion
        variant="outlined"
        slotProps={{ transition: { unmountOnExit: true } }}
        defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
          aria-controls="panel1-content"
          id="status">
          <CustomText fontWeight={600}>Status</CustomText>
        </AccordionSummary>
        <FilterAccordionDetail>
          {statuses?.slice(0, showMore ? statuses?.length : 6).map((item) => (
            <FormGroup key={item}>
              <FormControlLabel
                control={<CustomCheckbox sx={{ color: theme?.checkbox }} />}
                label={item}
                name={item}
                id={item}
                checked={filter.status.includes(item) ? true : false}
                onChange={handleFilterStatus}
              />
            </FormGroup>
          ))}
          {statuses?.length > 4 && (
            <Link
              component="button"
              variant="body2"
              color={theme?.textLink}
              onClick={() => {
                handleShowMore();
              }}>
              {showMore ? "Show Less" : "Show More"}
            </Link>
          )}
        </FilterAccordionDetail>
      </FilterAccordion>
      <FilterAccordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
          id="date">
          <CustomText fontWeight={600}>Due Date</CustomText>
        </AccordionSummary>
        <FilterAccordionDetail>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body2" gutterBottom>
                Start Date
              </CustomText>
              <InputDate
                id="start_date"
                name="start_date"
                value={filter.start_date}
                onChange={(e) => handleDueDate(e)}
              />
            </Stack>
            <Stack>
              <CustomText variant="body2" gutterBottom>
                End Date
              </CustomText>
              <InputDate
                id="end_date"
                name="end_date"
                minDate={tempStartDate}
                value={filter.end_date}
                onChange={(e) => handleDueDate(e)}
              />
            </Stack>
          </Stack>
        </FilterAccordionDetail>
      </FilterAccordion>
      <FilterAccordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
          id="date">
          <CustomText fontWeight={600}>Order Date</CustomText>
        </AccordionSummary>
        <FilterAccordionDetail>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body2" gutterBottom>
                Start Date
              </CustomText>
              <InputDate
                id="order_date_start"
                name="order_date_start"
                value={filter.order_date_start}
                onChange={(e) => handleOrderDate(e)}
              />
            </Stack>
            <Stack>
              <CustomText variant="body2" gutterBottom>
                End Date
              </CustomText>
              <InputDate
                id="order_date_end"
                name="order_date_end"
                minDate={tempOrderDateStart}
                value={filter.order_date_end}
                onChange={(e) => handleOrderDate(e)}
              />
            </Stack>
          </Stack>
        </FilterAccordionDetail>
      </FilterAccordion>

      {page === "item" && (
        <FilterAccordion
          // defaultExpanded
          variant="outlined"
          slotProps={{ transition: { unmountOnExit: true } }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
            aria-controls="panel1-content">
            <CustomText fontWeight={600}>Supplier</CustomText>
          </AccordionSummary>
          <FilterAccordionDetail>
            <SupplierAutocomplete />
          </FilterAccordionDetail>
        </FilterAccordion>
      )}
    </Box>
  );
}
