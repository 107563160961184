import { HideLoading, ShowLoading } from "src/components/main/Loading";
import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import {
  sendRequestDELETE,
  sendRequestGET,
  sendRequestPOST,
  sendRequestPUT,
} from "../sendRequest";
import NotificationAlert from "src/components/main/Alert";
import { useLoadingStore } from "src/store/loadingStore";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();

export const GetShipmentByCustomer = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_SHIPMENT_CUSTOMER || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    ShowLoading({});

    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION SHIPMENT CUSTOMER", res);
    HideLoading();

    return res.data;
  } catch (error: any) {
    HideLoading();

    console.error("ACTION ERROR SHIPMENT CUSTOMER", error);
    NotificationAlert({ message: error.message, status: "error" });
    throw new Error(error);
  }
};

export const deleteShipment = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_ADDRESS || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestDELETE(`${url}/${id}`, header);
    console.info("ACTION DELETE SHIPMENT", res);
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR DELETE SHIPMENT", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const updateCustomerAddress = async (data: CustomerAddress) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_ADDRESS || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    data.is_international = (data?.country ?? "").toLowerCase() !== "indonesia"
    showLoading();
    const res = await sendRequestPUT(url, data, header);
    console.info("ACTION UPDATE CUSTOMER ADDRESS", res);
    showNotification(res?.data?.message, "success");
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR UPDATE CUSTOMER ADDRESS", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const createCustomerAddress = async (data: CustomerAddress) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_ADDRESS || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION CREATE CUSTOMER ADDRESS", res);
    showNotification(res?.data?.message, "success");
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR CREATE CUSTOMER ADDRESS", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

