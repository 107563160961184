import {
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  deleteShipment,
  GetShipmentByCustomer,
  updateCustomerAddress,
  createCustomerAddress,
} from "src/api/shipment";
import NotificationAlert from "src/components/main/Alert";
import CustomButton from "src/components/main/CustomButton";
import CustomCard from "src/components/main/CustomCard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddressForm from "./AddressForm";
import ModalConfirm from "src/components/main/ModalConfirm";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import countries from "src/assets/countries.json";
import provinces from "src/assets/provinces.json";
import regencies from "src/assets/regencies.json";
import { capitalizeEachWord } from "src/utils/formatter";
import { useOrderStore } from "src/store/orderStore";
import { log } from "console";
import { addCustomerShipmentAddress } from "src/api/customer";

export default function ShippingAddress() {
  const { newShipment, setNewShipment } = useOrderStore();

  const filter = createFilterOptions();
  const [editStates, setEditStates] = useState<{ [key: number]: boolean }>({});
  const [initialData, setInitialData] = useState<CustomerAddress[]>([]);
  const [shipmentData, setShipmentData] = useState<CustomerAddress[]>([]);
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [newForm, setNewForm] = useState<boolean>(false);
  const [dataCreate, setDataCreate] = useState<CustomerAddress>(
    newShipment.shipment as CustomerAddress
  );

  const id = useLocation().pathname.split("/").pop();

  const fetchShippingAddress = async () => {
    try {
      const response = await GetShipmentByCustomer(id!);
      if (response?.data !== null) {
        setInitialData(response?.data);
        setShipmentData(response?.data);
      }
    } catch (error: any) {
      console.error("Error fetching customer shipment:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  useEffect(() => {
    fetchShippingAddress();
  }, []);

  const handleChange = (event: any, index: number) => {
    const { name, value, codeCountry } = event.target;
    const newData = [...shipmentData];

    // Ensure the object at the specified index exists

    // Dynamically add or update the properties
    newData[index] = {
      ...newData[index],
      [name]: value,
      ...(codeCountry && { codeCountry }), // Add codeCountry if available
    };
    setShipmentData(newData); // Update state
  };

  const handleDiscard = (index: number) => {
    setEditStates((prev) => ({ ...prev, [index]: false }));
    const newData = [...shipmentData];
    newData[index] = { ...newData[index], ...initialData[index] };
    setShipmentData(newData);
  };

  const handleEdit = (index: number) => {
    setEditStates((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleOpenModalDelete = (id: string) => {
    setOpenModalDelete(true);
    setSelectedData(id);
  };

  const handleDelete = async () => {
    await deleteShipment(selectedData);
    await fetchShippingAddress();
    setOpenModalDelete(false);
    setSelectedData("");
  };

  const handleOpenModalUpdate = (index: number) => {
    setOpenModalUpdate(true);
    setSelectedIndex(index);
  };

  const handleUpdate = async () => {
    await updateCustomerAddress(shipmentData[selectedIndex]);
    await fetchShippingAddress();
    setEditStates((prev) => ({
      ...prev,
      [selectedIndex]: !prev[selectedIndex],
    }));
    setOpenModalUpdate(false);
    setSelectedIndex(0);
  };

  const handleOpenModalCreate = () => {
    setOpenModalCreate(true);
  };

  const handleChangeCreate = (e: any) => {
    const { name, value } = e.target;

    // If province is being changed, reset city
    if (name === "province") {
      setDataCreate((prevData: CustomerAddress) => ({
        ...prevData,
        [name]: value,
        city: "", // Reset city value when province changes
      }));
    } else {
      setDataCreate((prevData: CustomerAddress) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleConfirmSave = async () => {
    // Optional: Add any logic needed to confirm the modal action
    const req = {
      idCustomer: id,
      shipmentData: dataCreate,
    };
    // Set the new shipment state
    await addCustomerShipmentAddress(req);
    await fetchShippingAddress();
    setNewForm(false);
    // Close the modal after submission
    setOpenModalCreate(false);
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setNewShipment({
      isNewShipment: true,
      shipment: {
        province: dataCreate?.province,
        city: dataCreate?.city,
        country: dataCreate?.country,
        village: dataCreate?.village,
        district: dataCreate?.district,
        address: dataCreate?.address,
        postal_code: dataCreate?.postal_code,
        recipient_name: dataCreate?.recipient_name,
        recipient_contact: dataCreate?.recipient_contact,
        type_shipment: dataCreate?.type_shipment,
        codeCountry: dataCreate?.codeCountry,
      },
    });
  };

  const buttonDelete = (id: string, index: number) => {
    return (
      <IconButton
        sx={{
          "&:hover": {
            backgroundColor: "rgba(255,255,255, 0.2)",
          },
        }}
        onClick={() => handleOpenModalDelete(id)}>
        <DeleteOutlineOutlinedIcon color="error" />
      </IconButton>
    );
  };

  return (
    <Box>
      {shipmentData?.map?.((data, index) => (
        <>
          {" "}
          <CustomCard
            key={index}
            text={`Address ${index + 1}`}
            actionHeader={buttonDelete(data.id, index)}>
            <Box width={"100%"} display={"flex"} flexDirection={"column"}>
              <Stack mb={2}>
                <CustomText variant="body1" required={editStates[index]}>
                  Recipient Name
                </CustomText>
                <CustomInput
                  disabled={!editStates[index]}
                  onChange={(e) => handleChange(e, index)}
                  size="small"
                  id="recipient_name"
                  name="recipient_name"
                  value={data?.recipient_name}
                />
              </Stack>
              <Stack mb={2}>
                <CustomText variant="body1" required={editStates[index]}>
                  Phone / Whatsapp
                </CustomText>
                <CustomInput
                  disabled={!editStates[index]}
                  onChange={(e) => handleChange(e, index)}
                  size="small"
                  id="recipient_contact"
                  name="recipient_contact"
                  value={data?.recipient_contact}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </Stack>
              <Stack mb={2}>
                <CustomText variant="body1" required={editStates[index]}>
                  Address Label
                </CustomText>
                <CustomInput
                  disabled={!editStates[index]}
                  onChange={(e) => handleChange(e, index)}
                  size="small"
                  id="type_shipment"
                  name="type_shipment"
                  value={data?.type_shipment}
                />
              </Stack>
              <Grid container columnSpacing={4}>
                <Grid item sm={6}>
                  <Stack mb={2}>
                    <CustomText variant="body1" required={editStates[index]}>
                      Country
                    </CustomText>
                    <Autocomplete
                      size="small"
                      id="country"
                      componentName="country"
                      disabled={!editStates[index]}
                      value={data?.country ?? ""} // Handle the value prop
                      onChange={(event, newValue) => {
                        const selectedCountry = countries.find(
                          (country) => country.name === newValue
                        );
                        handleChange(
                          {
                            target: {
                              name: "country",
                              value: newValue ?? "",
                              codeCountry: selectedCountry?.code ?? "",
                            },
                          },
                          index
                        );
                      }}
                      options={countries.map((option) => option.name)}
                      renderInput={(params) => (
                        <CustomInput
                          {...params}
                          required
                          disabled={!editStates[index]}
                        />
                      )}
                    />
                  </Stack>
                  <Stack mb={2}>
                    <CustomText variant="body1" required={editStates[index]}>
                      City
                    </CustomText>
                    {data?.country?.toLowerCase() === "indonesia" ? (
                      <Autocomplete
                        disabled={!editStates[index]}
                        size="small"
                        id="city"
                        componentName="city"
                        clearOnBlur
                        selectOnFocus
                        value={data?.city ?? ""} // Handle the value prop
                        onChange={(event, newValue: any) => {
                          let valueToSend;

                          if (typeof newValue === "string") {
                            valueToSend = newValue;
                          } else if (newValue && newValue.inputValue) {
                            valueToSend = {
                              label: newValue?.inputValue,
                              value: newValue?.inputValue,
                            };
                          } else {
                            valueToSend = newValue ? newValue : "";
                          }

                          handleChange(
                            {
                              target: {
                                name: "city",
                                value: valueToSend.value
                                  ? valueToSend.value
                                  : valueToSend,
                              },
                            },
                            index
                          );
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          const isExisting = options.some(
                            (option) => inputValue === option.name
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              value: `Add "${inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        options={regencies
                          .filter(
                            (option) =>
                              option.province_id ===
                              (provinces.find(
                                (province) => province.name === data?.province
                              )?.id ?? 0)
                          )
                          .map((option) => capitalizeEachWord(option.name))}
                        getOptionLabel={(option) => {
                          // Add "Add" label to the new option
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.value;
                          }
                          return option.value;
                        }}
                        renderInput={(params) => (
                          <CustomInput
                            {...params}
                            name="city"
                            id="city"
                            required
                            disabled={!editStates[index]}
                          />
                        )}
                      />
                    ) : (
                      <CustomInput
                        disabled={!editStates[index]}
                        required
                        size="small"
                        id="city"
                        name="city"
                        value={data?.city}
                        onChange={(e) => handleChange(e, index)}
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item sm={6}>
                  <Stack mb={2}>
                    <CustomText variant="body1" required={editStates[index]}>
                      Province
                    </CustomText>
                    {data?.country?.toLowerCase() === "indonesia" ? (
                      <Autocomplete
                        size="small"
                        id="province"
                        componentName="province"
                        disabled={!editStates[index]}
                        value={data?.province ?? ""} // Handle the value prop
                        clearOnBlur
                        selectOnFocus
                        onChange={(event, newValue: any) => {
                          let valueToSend;

                          if (typeof newValue === "string") {
                            valueToSend = newValue;
                          } else if (newValue && newValue.inputValue) {
                            valueToSend = {
                              label: newValue?.inputValue,
                              value: newValue?.inputValue,
                            };
                          } else {
                            valueToSend = newValue ? newValue : "";
                          }

                          handleChange(
                            {
                              target: {
                                name: "province",
                                value: valueToSend.value
                                  ? valueToSend.value
                                  : valueToSend,
                              },
                            },
                            index
                          );
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          const isExisting = options.some(
                            (option) => inputValue === option.name
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              value: `Add "${inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        options={provinces.map((option) => option.name)}
                        getOptionLabel={(option) => {
                          // Add "Add" label to the new option
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.value;
                          }
                          return option.value;
                        }}
                        renderInput={(params) => (
                          <CustomInput
                            {...params}
                            name="province"
                            id="province"
                            required
                            disabled={!editStates[index]}
                          />
                        )}
                      />
                    ) : (
                      <CustomInput
                        disabled={!editStates[index]}
                        required
                        size="small"
                        id="province"
                        name="province"
                        value={data?.province}
                        onChange={(e) => handleChange(e, index)}
                      />
                    )}
                  </Stack>
                  <Stack mb={2}>
                    <CustomText variant="body1" required={editStates[index]}>
                      Postal Code
                    </CustomText>
                    <CustomInput
                      disabled={!editStates[index]}
                      onChange={(e) => handleChange(e, index)}
                      size="small"
                      id="postal_code"
                      name="postal_code"
                      value={data?.postal_code}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Stack mb={2}>
                <CustomText variant="body1" required={editStates[index]}>
                  Full Address
                </CustomText>
                <CustomInput
                  disabled={!editStates[index]}
                  onChange={(e) => handleChange(e, index)}
                  size="small"
                  id="address"
                  name="address"
                  value={data?.address}
                />
              </Stack>
            </Box>
            <Box
              mt={4}
              display={"flex"}
              width={"100%"}
              sx={{
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                justifyContent: "flex-end",
                alignItems: {
                  xs: "flex-start",
                  md: "flex-end",
                },
                gap: {
                  xs: 1,
                  md: 2,
                  lg: 3,
                },
              }}>
              {editStates[index] ? (
                <>
                  <CustomButton
                    variant="outlined"
                    customType="cancel"
                    onClick={() => handleDiscard(index)}>
                    Discard Changes
                  </CustomButton>
                  <CustomButton
                    onClick={() => handleOpenModalUpdate(index)}
                    variant="contained">
                    Save Changes
                  </CustomButton>
                </>
              ) : (
                <CustomButton
                  variant="contained"
                  onClick={() => handleEdit(index)}>
                  Edit
                </CustomButton>
              )}
            </Box>
          </CustomCard>
        </>
      ))}
      {newForm && (
        <CustomCard text={`Address ${shipmentData.length + 1}`}>
          <Box width={"100%"} display={"flex"} flexDirection={"column"}>
            <Stack mb={2}>
              <CustomText variant="body1" required>
                Recipient Name
              </CustomText>
              <CustomInput
                size="small"
                id="recipient_name"
                name="recipient_name"
                value={dataCreate?.recipient_name}
                onChange={handleChangeCreate}
              />
            </Stack>
            <Stack mb={2}>
              <CustomText variant="body1" required>
                Phone / Whatsapp
              </CustomText>
              <CustomInput
                size="small"
                id="recipient_contact"
                name="recipient_contact"
                value={dataCreate?.recipient_contact}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onChange={handleChangeCreate}
              />
            </Stack>
            <Stack mb={2}>
              <CustomText variant="body1" required>
                Address Label
              </CustomText>
              <CustomInput
                size="small"
                id="type_shipment"
                name="type_shipment"
                value={dataCreate?.type_shipment}
                onChange={handleChangeCreate}
              />
            </Stack>
            <Grid container columnSpacing={4}>
              <Grid item sm={6}>
                <Stack mb={2}>
                  <CustomText variant="body1" required={!dataCreate?.country}>
                    Country
                  </CustomText>
                  <Autocomplete
                    size="small"
                    id="country"
                    componentName="country"
                    value={dataCreate?.country ?? ""}
                    onChange={(event, newValue) =>
                      handleChangeCreate({
                        target: { name: "country", value: newValue ?? "" },
                      })
                    }
                    options={countries.map((option) => option.name)}
                    renderInput={(params) => (
                      <CustomInput {...params} required />
                    )}
                  />
                </Stack>
                <Stack mb={2}>
                  <CustomText variant="body1" required={!dataCreate?.province}>
                    City
                  </CustomText>
                  {dataCreate?.country?.toLowerCase() === "indonesia" ? (
                    <Autocomplete
                      size="small"
                      id="city"
                      componentName="city"
                      clearOnBlur
                      selectOnFocus
                      value={dataCreate?.city ?? ""}
                      onChange={(event, newValue: any) => {
                        let valueToSend;

                        if (typeof newValue === "string") {
                          valueToSend = newValue;
                        } else if (newValue && newValue.inputValue) {
                          valueToSend = {
                            label: newValue?.inputValue,
                            value: newValue?.inputValue,
                          };
                        } else {
                          valueToSend = newValue ? newValue : "";
                        }

                        handleChangeCreate({
                          target: {
                            name: "city",
                            value: valueToSend.value
                              ? valueToSend.value
                              : valueToSend,
                          },
                        });
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            value: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      options={regencies
                        .filter(
                          (option) =>
                            option.province_id ===
                            (provinces.find(
                              (province) =>
                                province.name === dataCreate?.province
                            )?.id ?? 0)
                        )
                        .map((option) => capitalizeEachWord(option.name))}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.value;
                        }
                        return option.value;
                      }}
                      renderInput={(params) => (
                        <CustomInput
                          {...params}
                          name="city"
                          id="city"
                          required
                          disabled={!dataCreate?.province} // Disable city input until province is selected
                        />
                      )}
                    />
                  ) : (
                    <CustomInput
                      required
                      size="small"
                      id="city"
                      name="city"
                      value={dataCreate?.city}
                      onChange={handleChangeCreate}
                      disabled={!dataCreate?.country} // Disable if country is not selected
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item sm={6}>
                <Stack mb={2}>
                  <CustomText variant="body1" required={!dataCreate?.country}>
                    Province
                  </CustomText>
                  {dataCreate?.country?.toLowerCase() === "indonesia" ? (
                    <Autocomplete
                      size="small"
                      id="province"
                      componentName="province"
                      value={dataCreate?.province ?? ""}
                      clearOnBlur
                      selectOnFocus
                      onChange={(event, newValue: any) => {
                        let valueToSend;

                        if (typeof newValue === "string") {
                          valueToSend = newValue;
                        } else if (newValue && newValue.inputValue) {
                          valueToSend = {
                            label: newValue?.inputValue,
                            value: newValue?.inputValue,
                          };
                        } else {
                          valueToSend = newValue ? newValue : "";
                        }

                        handleChangeCreate({
                          target: {
                            name: "province",
                            value: valueToSend.value
                              ? valueToSend.value
                              : valueToSend,
                          },
                        });
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            value: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      options={provinces.map((option) => option.name)}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.value;
                        }
                        return option.value;
                      }}
                      renderInput={(params) => (
                        <CustomInput
                          {...params}
                          name="province"
                          id="province"
                          required
                          disabled={!dataCreate?.country} // Disable province input until country is selected
                        />
                      )}
                    />
                  ) : (
                    <CustomInput
                      required
                      size="small"
                      id="province"
                      name="province"
                      value={dataCreate?.province}
                      onChange={handleChangeCreate}
                      disabled={!dataCreate?.country} // Disable if country is not selected
                    />
                  )}
                </Stack>
                <Stack mb={2}>
                  <CustomText variant="body1" required>
                    Postal Code
                  </CustomText>
                  <CustomInput
                    required
                    size="small"
                    id="postal_code"
                    name="postal_code"
                    value={dataCreate?.postal_code}
                    onChange={handleChangeCreate}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Stack mb={2}>
              <CustomText variant="body1" required>
                Full Address
              </CustomText>
              <CustomInput
                size="small"
                id="address"
                name="address"
                value={dataCreate?.address}
                onChange={handleChangeCreate}
              />
            </Stack>
          </Box>
          <Box
            mt={4}
            display={"flex"}
            width={"100%"}
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "flex-end",
              alignItems: {
                xs: "flex-start",
                md: "flex-end",
              },
              gap: {
                xs: 1,
                md: 2,
                lg: 3,
              },
            }}>
            <CustomButton
              variant="outlined"
              customType="cancel"
              onClick={() => setNewForm(false)}>
              Cancel
            </CustomButton>
            <CustomButton
              onClick={() => handleOpenModalCreate()}
              variant="contained">
              Save
            </CustomButton>
          </Box>
        </CustomCard>
      )}

      <Box textAlign="center" mb={2} mt={shipmentData.length === 0 ? 32 : ""}>
        {!newForm && (
          <CustomButton variant="contained" onClick={() => setNewForm(true)}>
            Add New Shipping Address
          </CustomButton>
        )}
      </Box>
      <ModalConfirm
        text="Are you sure you want to create this address?"
        open={openModalCreate}
        onClose={() => setOpenModalCreate(false)}
        onSubmit={handleConfirmSave}
      />
      <ModalConfirm
        text="Are you sure you want to update this address?"
        open={openModalUpdate}
        onClose={() => setOpenModalUpdate(false)}
        onSubmit={handleUpdate}
      />
      <ModalConfirm
        text="Are you sure you want to delete this address?"
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onSubmit={handleDelete}
      />
    </Box>
  );
}
